@import '~style-assets/colors';
@import '~style-assets/fontMixins';
@import '~style-assets/media-query-points';
@import '~style-assets/breakpoints.scss';

.list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0 0 100px;
  color: $e-black;
  background-color: #f5f6fa;

  i {
    color: $e-black;

    &:hover {
      color: $orange;
    }
  }

  & > i {
    margin: 10px;
  }

  @include for-768-down {
    flex-direction: column;

    &.desktopOnly {
      display: none;
    }
  }
}

.item {
  display: inline-block;
  margin: 10px;
  transition-delay: 2s;
  color: inherit;

  &.icon {
    margin: 0 5px;
  }

  &.login {
    margin: 0;
  }

  a {
    color: $e-black;

    &:hover {
      color: $orange;
    }
  }

  &:last-child a {
    border-color: $e-black;

    &:focus {
      border-color: $e-black;
    }

    &:active {
      border-color: $orange;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.link {
  display: flex;
  color: $e-black;
  font-size: 20px;
  transition: all 0.3s;
  align-items: center;

  &:hover {
    color: $orange;
    text-decoration: none;
    border-color: transparent;

    i {
      color: $orange;
    }
  }

  span {
    margin: 0 10px;
  }
}

.profileLink {
  i {
    color: $orange;
  }

  span {
    margin: 0 10px;
  }
}

.divider {
  width: 1px;
  height: 24px;
  background-color: $e-black;
  display: none;
  margin: 0 20px;
}

.notificationsOverlay {
  top: 72px !important;
  right: 78px !important;
  left: auto !important;

  @include for-tablet-landscape-down {
    top: 76px !important;
    right: 30px !important;
  }

  @include for-768-down {
    top: 62px !important;
    right: 25px !important;
  }

  @include for-phone-only {
    top: 60px !important;
    right: 12px !important;
  }
}

@media screen and (min-width: $min-s) {
  .list {
    margin: 0;
    padding: 0;
    width: initial;
    height: initial;
    position: relative;
    align-items: center;
    list-style-type: none;
    background-color: transparent;
    flex-direction: row;
    -webkit-transform: none;
    transform: none;
  }

  .item {
    margin: 0 20px;
    &:nth-last-child(-n + 1) {
      margin: 0 5px 0 20px;

      a {
        display: flex;
      }
    }
  }

  .link {
    font-size: 14px;
  }
}

@media screen and (min-width: $min-sm) {
  .item {
    margin: 0 20px;
    &:nth-last-child {
      margin: 0 3px;
    }
  }
}

@media screen and (min-width: $min-sm) {
  .button {
    padding: 4px 20px;
    border-radius: 18px;
    border: 1px solid transparent;

    &:hover {
      background-color: $orange;
      color: $e-white !important;
      border-color: transparent !important;
    }

    &:focus {
      border-color: $e-black;
    }

    &:active {
      background-color: $orange;
      color: $e-white;
      border-color: $orange;
    }
  }

  .list {
    flex-direction: row;
  }

  .divider {
    display: block;
  }
}

.bell {
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50px;
    background-color: $orange;
    position: absolute;
    right: 0;
    top: -2px;
  }
}
