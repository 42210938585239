@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 35px;
  color: $e-black;
  border-bottom: 9px solid $e-white;
  width: 50%;

  @include for-768-down {
    align-items: center;
    text-align: center;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    border-right: none;
  }

  :global {
    .ant-form-item-label {
      margin-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  h3 {
    margin: 0 0 30px;
    margin-right: auto;

    font-size: 18px;
    @include helvetica-medium;

    @media only screen and (max-width: 550px) {
      margin-left: auto;
    }
  }

  form {
    padding: 0;
    margin-bottom: 0;
    margin-right: auto;
    text-align: left;

    @media only screen and (max-width: 550px) {
      margin-left: auto;
    }

    @include for-phone-only {
      min-width: 260px;
      width: auto;
    }

    label {
      font-size: 16px;
    }

    input {
      width: 350px;
    }

    & > div:last-child {
      width: 120px;

      @include for-768-down {
        margin: 0 auto;
      }
    }

    button,
    button:hover,
    button:active,
    button:focus {
      margin-left: 0;
    }
  }
}

.block {
  margin-right: auto;

  @media only screen and (max-width: 550px) {
    margin-left: auto;
  }

  p {
    width: 100% !important;
  }
}

.title {
  font-size: 16px;
  margin-bottom: 5px;
}

.phone {
  @include helvetica-medium;

  margin-bottom: 20px;
  text-align: left;
}
