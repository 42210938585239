@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.text {
  @include helvetica-regular;
  font-size: 14px;
  color: $e-black;
  width: 200px !important;

  @media only screen and (max-width: 550px) {
    width: 320px !important;
  }

  @include for-phone-only {
    width: 260px !important;
  }
}

div ul li.search {
  input {
    @extend .text;
    width: 80% !important;
    padding: 3px 4px 3px 10px !important;
  }
}

.list {
  @extend .text;
}

.error {
  color: $orange;
}

.redBorder {
  border: 1px solid $orange !important;
}

.signup {
  width: 100% !important;
  height: 26px !important;
}
