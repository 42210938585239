@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.aside {
  background-color: white;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 260px;
  box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
  @include helvetica-regular;
  font-size: 16px;

  @include for-768-down {
    width: 100%;
    height: 320px;
    display: flex;
    flex-wrap: wrap;
    order: 2;
  }

  @include for-tablet-portrait-down {
    height: 370px;

    position: relative;
  }

  @include for-phone-only {
    height: 310px;
  }

  @include super-small {
    height: auto;
  }
}

.logo {
  height: 105px;
  margin: 3px 0;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include for-tablet-portrait-down {
    width: 45%;
  }

  @include super-small {
    width: 100%;
    height: auto;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.distributed {
  background-color: $green;
  color: white;
  font-size: 18px;
  @include helvetica-medium;
  padding-left: 12px;
  height: 60px;
  display: flex;
  align-items: center;

  @include for-768-down {
    padding: 0 12px;
  }

  @include for-tablet-portrait-down {
    width: 45%;
    font-size: 16px;
    text-align: center;
  }

  @include super-small {
    width: 100%;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 0 13px;

  @include for-tablet-portrait-down {
    width: 45%;
    margin-bottom: 0;
    padding: 0;
  }

  @include super-small {
    width: 100%;
    padding: 0 16px;
  }

  h1 {
    font-size: 27px;
    @include helvetica-bold;
    margin: 20px 0 5px;
    padding: 0 13px;

    @include for-768-down {
      font-size: 22px;
    }

    @include for-phone-landscape-down {
      font-size: 18px;
    }

    @include for-phone-only {
      margin-top: 0;
    }
  }
}

h2 {
  padding: 16px 13px 0;
  margin-bottom: 0;

  @include for-768-down {
    font-size: 18px;
  }

  @include for-phone-landscape-down {
    font-size: 16px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $e-white;
  align-self: center;
  margin: 5px 0;

  @include for-tablet-portrait-down {
    margin-top: 0;
  }
}

.icon {
  color: $green;
  margin-right: 10px;
}

.address {
  display: flex;
  flex-direction: column;
  align-content: center;
  font-size: 16px;

  @include helvetica-medium;

  div {
    margin: 5px 0;
    font-size: 14px;
    display: flex;
    align-items: center;

    @include for-tablet-portrait-down {
      margin-top: 0;
    }
  }

  @include for-phone-only {
    margin-top: 6px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 13px;
  font-size: 16px;

  @include for-768-down {
    padding-top: 20px;
  }

  @include for-tablet-portrait-down {
    width: 37%;
    order: 2;

    padding-top: 45px;
  }

  @include for-phone-landscape-down {
    width: 45%;
    padding-top: 35px;
  }

  @include for-phone-only {
    font-size: 14px;
    padding: 35px 0 0;
  }

  @include super-small {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    padding: 0 16px 90px;
  }
}

.item {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include for-768-down {
    flex-direction: row;
    justify-content: flex-start;
  }

  @include for-tablet-portrait-down {
    margin-top: 0;
    flex-direction: column;
    justify-content: center;
  }

  @include super-small {
    width: 50%;
  }

  .vestingValue {
    @include helvetica-bold;
    color: $green;
  }

  .value {
    color: $green;

    @include super-small {
      display: block;
      @include helvetica-medium;
    }
  }
}

.footer {
  background-color: $e-black;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-768-down {
    width: 100%;
    order: 1;
    position: absolute;
    bottom: 0;
    display: none;
  }

  p {
    color: $e-white;
    font-size: 14px;
    letter-spacing: 0.3px;
    padding: 25px 13px 11px 13px;

    @include for-tablet-portrait-down {
      text-align: center;
    }
  }

  input {
    color: $e-white;
  }

  .asideEmailForm {
    form {
      width: 100%;
      max-width: unset;
      padding: 0 13px;

      input {
        width: 100%;
        color: $white;
      }

      button {
        width: 100%;
      }
    }
  }
}

.closedAsset {
  @include for-768-down {
    div {
      width: 100%;
      text-align: center;
    }

    .address {
      div {
        display: flex;
        justify-content: center;
      }
    }
  }

  @include for-tablet-portrait-down {
    height: auto;
  }

  @include super-small {
    .logo {
      margin: 20px 0;
    }
  }
}

.button {
  text-transform: uppercase;
  border-radius: 18px;
  color: $e-white;
  background-color: $e-black;
  font-size: 14px;
  padding: 7px 0;
  height: 35px;
  width: 152px;
  margin: 20px auto 30px;
  display: flex;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: $green;
    color: $e-white;
    border-color: transparent;
    box-shadow: 0 3px 2px 0 $dove-gray;
  }

  &:active {
    background-color: $e-black;
    color: $e-white;
    bottom: -2px;
  }
}
