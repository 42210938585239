@import '~style-assets/fontMixins.scss';
@import '~style-assets/colors';
@import '~style-assets/forms';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $e-white;
  min-height: 100vh;
}

.header {
  background-color: #fff;
  width: 100%;
  padding: 8px 25%;
  margin-bottom: 24px;
  box-shadow: rgba($color: #000, $alpha: 0.16) 0 3px 6px;
}

.title {
  color: $e-black;
}

.form {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.actions {
  align-self: flex-end;
}

.legend {
  width: 40%;
  align-self: flex-end;

  input {
    min-height: 67px;
    border-radius: 4px;
    border: 1px solid $e-white;
  }
}

.label {
  color: $e-black;
  font-size: 16px;
  margin-bottom: 0;
}

.subtitle {
  font-size: 12px;
  text-align: left;
  margin-bottom: 8px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.addImage {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  margin: 20px 0 0 auto;
  background-color: red;
}
