@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @include helvetica-regular;

  form {
    max-width: 254px;
    min-width: unset;

    & > div:first-of-type {
      margin-bottom: 0;
    }
  }

  input {
    background-color: transparent;
    width: 152px;
    height: 25px;
    font-size: 13px;
    color: $e-white !important;
    border-radius: 0;
  }

  button {
    margin: 0 0 30px;
    width: 152px;
    border-radius: 16px;
    text-transform: uppercase;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: $orange;
      color: $e-white;
      border-color: transparent;
      box-shadow: 0 3px 2px 0px $dove-gray;
    }

    &:active {
      background-color: $e-white;
      color: $e-black;
      bottom: -2px;
    }
  }
}
