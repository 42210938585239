@import '~style-assets/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  width: 150px;
  height: 80px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
}

.icon {
  width: 30px;
  height: 30px;
}

.header {
  color: $black;
}

.footer {
  font-size: 9px;
  margin-top: -6px;
}

.drop {
  font-size: 10px;
  width: 75px;
  line-height: 1.4;
  text-align: center;
  margin-top: 6px;
}

.name {
  @extend .drop;
  width: 140px;
  overflow: hidden;
}

.link {
  color: $blue;
  text-decoration: underline;
}
