@import "~style-assets/colors";

.menu {
  width: 230px;
  display: flex;

  :global {
    .ant-menu.ant-menu-dark.ant-menu-inline {
      &.ant-menu-root {
        background-color: $e-black;
      }

      .ant-menu-item {
        margin: 0;
        box-sizing: border-box;
        border-left: 5px solid transparent;

        a {
          color: #fff;
          font-size: 14px;
          display: flex;
          letter-spacing: 0.4px;
          align-items: center;
          justify-content: space-between;

          svg {
            color: transparent;
          }
        }

        &.ant-menu-item-selected {
          background-color: #fff;
          border-color: $orange;

          a {
            color: $orange;

            svg {
              color: $orange;
            }
          }
        }
      }
    }
  }
}
