@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 260px;
  height: 255px;
  background-color: #fff;
  margin: 8px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  width: 50%;
  height: 85%;
}

.label {
  font-size: 18px;
  @include helvetica-medium;
  text-align: center;
}
