@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 35px;
  color: $e-black;
  border-bottom: 9px solid $e-white;

  @include for-phone-only {
    padding: 28px 20px;
  }

  p {
    margin-bottom: 0;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;

  &:last-child {
    padding-top: 18px;
  }

  p {
    font-size: 16px;
  }
}

.modal {
  text-align: center;
  color: $e-black;
  top: 30%;

  @include helvetica-regular;

  :global {
    .ant-modal-content {
      border-radius: 0;
      box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
    }

    .ant-modal-body {
      padding: 0 0 20px;
    }

    .ant-modal-close :hover {
      color: black;
    }
  }

  .img {
    width: 30%;
  }

  p.title {
    padding: 10px 50px;
    font-size: 27px;
    background-color: $orange;
    color: $e-white;
    margin-bottom: 50px;

    @include for-tablet-portrait-down {
      font-size: 20px;
      padding: 0 16px;
    }
  }

  p {
    padding: 0 50px;
    font-size: 16px;
    margin-bottom: 50px;

    @include for-tablet-portrait-down {
      padding: 0 16px;
    }
  }

  p:last-child {
    padding: 0;
  }

  .row {
    text-align: center;
  }
}
