@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/media-query-points.scss';

.container {
  @include helvetica-regular;
  text-align: center;

  h3 {
    @include helvetica-bold;
    text-align: center;
    font-size: 27px;
    margin-top: 0;
  }
}

.button,
.button:focus {
  @include helvetica-regular;
  font-size: 10px;
  line-height: 24px !important;
  padding: 4px 30px;
  border-radius: 30px;
  height: 36px;
  text-transform: uppercase;
  margin: 10px;
  letter-spacing: 1px;
  background-color: $orange;
  color: $e-white;
}

.button {
  &:hover {
    background-color: $orange;
    border-color: $orange;
    color: $e-white;
    box-shadow: 0 3px 2px 0 $dove-gray;
  }

  &:focus {
    border-color: transparent;
  }

  &:active {
    bottom: -2px;
  }
}

@media screen and (min-width: $min-sm) {
  .button,
  .button:hover,
  .button:focus {
    width: 381px;
    font-size: 14px;
  }
}

@media screen and (min-width: $min-l) {
  .button,
  .button:hover,
  .button:focus {
    padding: 0 77px;
    line-height: 36px !important;
    margin: 0 auto;
  }
}
