@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/media-query-points.scss';
@import '~style-assets/breakpoints.scss';

.benefits {
  background: $e-black;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  color: $white;
  width: 100%;
  @include helvetica-regular;
  font-size: 16px;
  line-height: 20px;
  margin-top: 30px;

  .title {
    @include helvetica-medium;
    font-size: 27px;
    line-height: 31px;
    margin-bottom: 20px;
  }

  .image {
    margin-bottom: 20px;
    height: 54px;
  }

  .subtitle {
    @include helvetica-medium;
    position: relative;
    margin-bottom: 30px;

    &:after {
      content: '';
      display: block;
      background: $orange;
      height: 1px;
      width: 30px;
      position: absolute;
      left: 0;
      bottom: -15px;
    }

    @include for-768-down {
      height: unset;
    }
  }

  .benefit {
    flex: 1 auto 12%;
    @include helvetica-regular;
    &:first-of-type {
      flex: 1 auto 16%;
    }
    &:not(:last-of-type) {
      margin-right: 4%;
    }
  }
}

.wrap {
  max-width: 1100px;
  padding: 0 11px;
  display: flex;
}

@media screen and (max-width: $max-s) {
  .wrap {
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  .benefits {
    margin-top: 0;
    .subtitle {
      margin-bottom: 20px;
      &:after {
        position: static;
        margin: 10px auto 0;
      }
    }
    .benefit {
      width: 100%;
      margin: 0 0 60px 0;
      &:not(:last-of-type) {
        margin-right: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
