@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  color: $e-black;
  margin-bottom: 100px;
  @include helvetica-medium;
  width: 100vw;
}

.title {
  display: flex;
  font-size: 48px;
  height: 192px;
  background-color: #f2f1f1;
  width: 100%;
  align-items: center;
  justify-content: center;

  @include for-tablet-landscape-down {
    height: 120px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
}

.subtitle {
  font-size: 14px;
  @include helvetica-medium;
  margin: 70px 0 30px;
  text-align: center;
  position: relative;

  @include for-tablet-portrait-down {
    margin-top: 25px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 67px;
    height: 1px;
    background-color: $e-black;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);

    @include for-tablet-portrait-down {
      display: none;
    }
  }
}

.form {
  background-color: $white;
  padding: 20px;
  max-width: 500px;

  @include for-tablet-portrait-down {
    width: 90%;
  }

  @include for-phone-only {
    width: 100%;
  }

  :global {
    .ant-form-item-children-icon {
      display: none;
    }

    label::after {
      content: '';
    }

    label i {
      position: relative;
      font-style: normal;
      font-size: 16px;
    }

    .ant-form-item-label {
      line-height: 1.5;
    }

    .ant-form-item-children textarea {
      margin: 0;
    }

    .ant-select-selection__placeholder {
      display: block !important; // no other way to override ant's display none for select placeholder
    }
  }
}

.input {
  margin: 0;
  text-align: left;
}

.sInput {
  @extend .input;

  @include for-phone-only {
    width: 90%;
    margin: 0 auto;
  }

  &:nth-child(2n) {
    margin-right: 0;

    @include for-phone-only {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.submit,
.submit:hover,
.submit:focus {
  display: block;
  padding: 0 58px;
  margin: 24px auto;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $dove-gray;
  display: block;
  margin: 10px 0;
}

.subTitle {
  display: flex;
  justify-content: flex-start;

  & > span {
    @include helvetica-medium;
  }
}

.checkbox {
  @include for-phone-only {
    width: 90%;
    margin: 0 auto;
  }
  :global {
    .ant-form-item-children {
      .ant-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .ant-form-item-label {
          order: 1;
          margin-left: 10px;

          label {
            cursor: pointer;
            text-transform: capitalize;
          }
        }
      }
    }
    .ant-form-item-control-wrapper {
      @media (max-width: 575px) {
        width: unset;
      }
    }
  }
}

.text {
  color: $e-black;
  @include helvetica-regular;

  @include for-tablet-portrait-down {
    text-align: center;
  }
}

.customLabel {
  @include helvetica-regular;
}
