@import '~style-assets/colors';
@import '~style-assets/fontMixins';
@import '~style-assets/media-query-points';

:global {
  .ant-form-item-control {
    line-height: unset;
  }
}

.footer {
  @include helvetica-regular;

  background-color: $white;
  display: flex;
  font-size: 12px;
  color: $e-black;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px 30px;
  box-shadow: 0px -3px 6px #00000029;
}

.list {
  margin: 0;
  padding: 0;
  align-items: center;
  list-style-type: none;
  text-align: center;
}

.item {
  list-style: none;

  &:hover {
    cursor: pointer;
  }
}

.link {
  color: $e-black;
  font-size: 14px;
  line-height: 27px;
  transition: all 0.3s;

  &:hover:not(:active) {
    color: $orange;
    text-decoration: none;
    border-color: $orange;
  }

  &:focus,
  &:active {
    border-color: $orange;
  }
}

.header {
  text-align: center;
  color: $e-black;
  font-size: 10px;
}

.logo {
  height: 68px;
  margin-bottom: 10px;
}

.body {
  flex-direction: row;
  justify-content: space-between;
}

.title {
  @include helvetica-medium;
  color: $orange;
  font-size: 14px;
}

.subscribe {
  max-width: 254px;
  align-self: center;
  text-align: center;
  margin-top: 20px;

  p {
    line-height: 20px;
    width: 100%;
    font-size: 14px;
    color: $e-white;
  }
}

.contact {
  text-align: center;
  font-size: 14px;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -15px;
  justify-content: center;

  & > input,
  & > button {
    border-radius: 0;
    height: 27px;
    margin: 0;
    width: initial;
  }

  & > input {
    background: transparent;
    font-size: 12px;
    color: #fff;
  }

  & > button {
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-size: 12px;
    padding: 0 20px;
  }
}

@media screen and (min-width: $min-s) {
  .logo {
    height: 68px;
  }

  .footer {
    flex-direction: row;

    form {
      display: flex;
      flex-direction: row;

      div:first-of-type {
        margin-bottom: 0;
        height: 34px;
      }

      input {
        height: 34px;
        width: 100%;
      }

      button {
        width: 108px;
        height: 34px;
        border-radius: 0;
        margin: 0;
      }
    }
  }

  .subscribe {
    align-self: initial;
    text-align: initial;
    margin: 0;
    margin-left: 16px;
  }
  .list,
  .contact {
    text-align: initial;
  }
}

@media screen and (max-width: $max-s) {
  .contact {
    margin-top: 40px;
  }
}

@media screen and (min-width: $min-sm) {
  .logo {
    height: 75px;
  }
}

@media screen and (min-width: $min-l) {
  .header {
    text-align: initial;
    margin: 0;
  }

  .form {
    margin-top: 10px;
    justify-content: initial;
  }
}
