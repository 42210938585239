@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  max-width: 720px;
  flex-wrap: wrap;

  form {
    flex: 1;
    max-width: 320px;
    background-color: $white;
    margin: 0;

    :global {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.left {
  background-color: $e-black;
  padding: 15px 24px 0;
  font-size: 16px;
  color: $e-black;
  flex: 1;
  max-width: 360px;

  @include helvetica-regular;
}

.block {
  margin-top: 24px;

  .transfer {
    color: $e-white;
    text-align: left;
    margin-top: 6px;
  }
}

h4 {
  color: $e-white;
  text-align-last: left;
  margin-bottom: 8px;
  @include helvetica-regular;
}

.info {
  background-color: $e-white;
  padding: 0 17px;
}

.title {
  padding-top: 10px;
  margin-bottom: 0;
  @include helvetica-bold;
}

.location {
  font-size: 14px;
  margin-bottom: 10px;
}

.divider {
  height: 1px;
  width: 100%;
  margin: 0 auto 12px;
  background-color: $e-black;
}

.estimation {
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 6px;
  }
}

.financial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $e-white;

  p:first-child {
    padding-left: 20px;
  }

  p:last-child {
    padding-right: 20px;
  }

  p {
    margin-bottom: 0;
    font-size: 30px;
  }
}

.form {
  padding: 15px 20px 0;
  margin: 0;
  background-color: $white;

  @include for-phone-only {
    padding-bottom: 15px;
  }

  .title {
    font-size: 18px;
    @include helvetica-medium;
    margin-bottom: 25px;
  }
}

.row {
  margin: 0;
  text-align: left;
  width: 100%;

  .label {
    font-size: 16px;
    margin-bottom: 0;
  }

  .data {
    font-size: 14px;
    height: 32px;
    padding: 4px 11px;
    background-color: $white;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
}

.actions {
  display: flex;
  width: 370px;
  justify-content: flex-end;
  margin-left: auto;
  padding-top: 20px;
}

.btn {
  font-size: 14px;

  &:hover,
  &:active,
  &:focus {
    font-size: 14px;
  }
}
