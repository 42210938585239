@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $e-black;
  border-bottom: 1px solid #e8e8e8;

  @include for-768-down {
    align-items: center;
  }

  .heading {
    font-size: 18px;
    @include helvetica-medium;

    padding: 28px 35px;
  }

  .row {
    width: 100%;
    border-bottom: 9px solid $e-white;
  }

  :global {
    .ant-table-wrapper {
      margin: 0 auto;
    }

    .ant-table-thead > tr > th {
      background-color: transparent;

      @include helvetica-medium;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 12px;
      text-align: center;

      @include for-phone-only {
        padding: 3px;
        font-size: 12px;

        p {
          font-size: 12px;
        }
      }
    }

    .ant-select-selection__rendered {
      @include super-small {
        margin: 0 5px;
      }
    }
  }
}

.APPROVED {
  text-transform: capitalize;
  color: $green;

  @include super-small {
    transform: scale(1.5);
    font-size: 0;
  }
  &::before {
    margin-right: 8px;
    content: url(../../../assets/images/contributions/status-verified.svg);

    @include super-small {
      margin-right: 0;
    }
  }
}

.PENDING {
  text-transform: capitalize;
  color: $yellow;

  @include super-small {
    font-size: 0;
    transform: scale(1.5);
  }

  &::before {
    margin-right: 8px;
    content: url(../../../assets/images/contributions/status-pending.svg);

    @include super-small {
      margin-right: 0;
    }
  }
}

.DECLINED {
  text-transform: capitalize;
  color: $orange;

  @include super-small {
    font-size: 0;
    transform: scale(1.5);
  }

  &::before {
    margin-right: 8px;
    content: url(../../../assets/images/contributions/status-unverified.svg);

    @include super-small {
      margin-right: 0;
    }
  }
}

.popover {
  font-size: 12px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 9px solid $e-white;

  padding-bottom: 80px;

  .button,
  .button:hover,
  .button:active,
  .button:focus {
    font-size: 14px;
  }
}

.transactions {
  font-size: 27px;
  text-align: center;

  @include for-phone-only {
    font-size: 18px;
  }
}

.lostAccess {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 35px;
}

.table {
  padding: 0 35px;

  :global {
    .ant-select-enabled {
      width: 90%;
    }
  }

  .select {
    padding: 0 !important;
  }
}
