@import '~style-assets/colors';
@import '~style-assets/fontMixins';
@import '~style-assets/media-query-points';
@import '~style-assets/breakpoints.scss';

.header {
  @include helvetica-regular;
  position: fixed;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  flex-direction: column;
  top: -100%;
  width: 100%;
  max-width: 100%;
  padding: 0 15px;
  background-color: $white;
  text-align: right;
  transition: .5s top ease-in-out;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: 0 0 10px $dove-gray;

  &.headerIsVisible {
    top: 0;
    z-index: 1001;
  }

  a {
    align-self: flex-start;
  }
}

.logo {
  height: 40px;
  margin: 10px;
}

.menuIcon {
  display: none;
  position: absolute;
  font-size: 23px;
  top: 18px;
  right: 28px;
  transition: all 0.5s ease;

  @include for-768-down {
    display: block;
  }
}

.drawer {
  :global {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
    .ant-drawer-body {
      padding: 0 !important;
    }
  }
}

@media screen and (min-width: $min-s) {
  .header {
    padding-bottom: 20px;
  }

  .logo {
    height: 35px;
  }
}

@media screen and (min-width: $min-sm) {
  .header {
    flex-direction: row;
    padding: 20px 40px;

    a {
      align-self: center;
    }
  }
}

@media screen and (min-width: $min-sm) {
  .header {
    height: 108px;
    background-color: white;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    text-align: right;
    box-sizing: border-box;
    padding: 0 25px;
  }

  .logo {
    height: 75px;
    margin: 0;
  }
}

@media screen and (min-width: $min-xl) {
  .header {
    padding: 0 75px;
  }
}
