.table {
  flex: 1;
  align-self: stretch;
  padding: 15px;

  :global {
    .ant-tag {
      margin: 0;
    }
  }
}

.deploySuccess {
  width: 850px;
}