@import '~style-assets/colors';
@import '~style-assets/fontMixins';

.root {
  min-height: 100vh;
  height: 100%;
  background: $white;
}

.menu {
  line-height: 64px;
}

.logout {
  float: right;
}

.content {
  display: flex;
}
