@charset "UTF-8";
.content {
  padding: 100px 0;
  justify-content: center;
  font-family: 'HelveticaNeue';
  font-weight: 400;
  min-height: calc(100vh - 352px); }

.form {
  background-color: #ffffff;
  padding: 24px;
  width: 350px;
  margin: 0 auto; }
  @media (max-width: 460px) {
    .form {
      width: 100%; } }
  .form a {
    color: #00AC62; }
    .form a:hover, .form a:active {
      color: #262626; }
  .form :global .ant-form-item-children-icon {
    display: none; }
  .form :global .ant-radio-wrapper {
    display: block;
    color: #262626; }

.input {
  margin: 0;
  text-align: left;
  width: 100%; }
  .input :global .ant-form-item-required::before {
    display: none; }
  .input :global .ant-form-item-required::after {
    content: '★';
    color: #00AC62;
    font-size: 7px;
    top: -6px;
    right: -2px; }

.bottom {
  text-align: center; }
  .bottom button,
  .bottom button:active,
  .bottom button:hover,
  .bottom button:focus {
    width: 100%;
    font-size: 14px;
    margin: 10px 0; }
