@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';
.root {
  width: 340px;
  min-height: 50px;
  box-shadow: 0 0 3px $dove-gray;

  max-height: 50vh;
  overflow-y: scroll;
  background-color: $white;

  @include helvetica-regular;

  @include for-768-down {
    width: 420px;
  }

  @include for-tablet-portrait-down {
    max-height: 60vh;
  }

  @include for-phone-only {
    max-width: 295px;
  }
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 10px 4px;
}

.title {
  @include helvetica-medium;
  font-size: 14px;
  margin: 0;
}

.counter {
  color: $white;
  background: #092035;
  border-radius: 26px;
  padding: 3px 5px;
  font-size: 12px;
  text-transform: uppercase;
}

.divider {
  height: 1px;
  width: 100%;
  display: block;
}

.vDivider {
  height: 100%;
  width: 1px;
  display: block;
}

.body {
  font-size: 12px;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 3px 0;

  &:last-child {
    border-bottom: none;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowEmpty {
  padding: 16px;
  flex-direction: column;

  img {
    margin-bottom: 16px;
  }

  p {
    text-align: center;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.left {
  @extend .column;
  text-align: left;
}

.right {
  @extend .column;
  text-align: center;
}

.button {
  padding: 15px 10px;
  cursor: pointer;
}

.primaryButton {
  @extend .button;
  font-weight: bold;
  color: $blue;
}

.secondaryButton {
  @extend .button;
  color: $dove-gray;

  &:hover {
    color: $orange;
  }
}

.icon {
  color: $yellow;
  font-size: 20px;
  padding: 0 10px;
}

.subTitle {
  color: $yellow;
  font-weight: 500;
  margin-bottom: -3px;
}

.success {
  color: $green;
}

.error {
  color: $red;
}

.text {
  color: $black;
}
