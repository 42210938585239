@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/forms';
@import '~style-assets/breakpoints.scss';

.form {
  width: 30%;
  align-self: center;
  margin: auto;
  background-color: red;
}

.icon {
  color: rgba(0,0,0,.25);
}

.input {
  @extend .form-input;
}