@import '~style-assets/colors.scss';

.container {
  max-width: 640px;
  margin: 0 auto 24px;

  :global {
    .slick-next:hover,
    .slick-prev:hover {
      &:before {
        color: $orange;
      }
    }

    .slick-next,
    .slick-prev {
      top: 0;
      transform: unset;
      width: 30px;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }
    .slick-slide {
      & > div {
        height: 100%;
      }
    }

    .slick-arrow {
      margin-top: -3px;
      height: 50%;
      width: 30px;
      background-color: rgba(0,0,0,0.1);

      top: 50%;
      transform: translate(0, -50%);

      &:hover {
        background-color: rgba(0,0,0,0.4);
      }

      &:before {
        opacity: 1 !important;
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 15px;
        position: relative;
        top: 50%;
        vertical-align: top;
        width: 15px;
        transform: rotate(45deg);
        font-size: 24px;
        color: $white;
      }

      &.slick-next {
        right: 0;

        &:before {
          left: 4px;
        }
      }

      &.slick-prev {
        left: 0;
        z-index: 1;

        &:before {
          left: 12px;
          transform: rotate(-135deg);
        }
      }
    }
  }
}

.top {
  img {
    width: 100%;
    max-width: 640px;
    max-height: 380px;
  }

  button {
    z-index: 1;
  }

  :global {
    .slick-track {
      display: flex;
      align-items: center;
    }
  }
}

.bottom {
  img {
    width: 100%;
    height: 130px;
  }

  button {
    z-index: 1;
  }

  :global {
    .slick-arrow {
      background-color: transparent;
      height: 130px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }

      &.slick-next {
        &:before {
          left: -2px;
        }
      }

      &.slick-prev {
        &:before {
          left: 5px;
        }
      }
    }

  }
}

.image {
  cursor: pointer;
}

.fullScreen {
  background-color: rgba(37, 41, 48, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  z-index: 10;

  .top {
    img {
      max-height: 68vh;
      max-width: unset;
    }
  }

  .bottom {
    img {
      height: 22vh;
    }
  }
}
