@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 35px;
  color: $e-black;
  border-bottom: 9px solid $e-white;
  border-right: 9px solid $e-white;
  width: 50%;

  @include for-768-down {
    align-items: center;
    text-align: center;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    border-right: none;
  }

  :global {
    .ant-form-item-label {
      margin-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  h3 {
    margin: 0 0 30px;
    font-size: 18px;
    @include helvetica-medium;
    margin-right: auto;

    @media only screen and (max-width: 550px) {
      margin-left: auto;
    }
  }

  p {
    margin-bottom: 10px;
  }

  form {
    padding: 0;
    margin-bottom: 0;
    margin-right: auto;

    min-width: 200px;
    width: auto;

    @media only screen and (max-width: 550px) {
      margin-left: auto;
      min-width: 320px;
    }

    @include for-phone-only {
      min-width: 260px;
    }

    label {
      font-size: 16px;
    }

    & > div:last-child {
      width: 120px;

      @include for-768-down {
        margin: 0 auto;
      }
    }
  }

  button,
  button:hover,
  button:active,
  button:focus {
    margin-left: 0;
  }
}
