@import '~style-assets/fontMixins';
@import '~style-assets/breakpoints.scss';
@import '~style-assets/colors';
@import '~style-assets/forms';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $e-white;
}

.header {
  background-color: #fff;
  width: 100%;
  padding: 8px 18%;
  margin-bottom: 24px;
  box-shadow: rgba($color: #000, $alpha: 0.16) 0 3px 6px;

  @include for-desktop-down {
    padding: 8px 12%;
  }

  @include for-tablet-landscape-down {
    padding: 8px 10%;
  }
}

.pagination {
  display: inline-block;
  color: $e-black;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid $e-black;
  padding: 2px 12px;
}

.title {
  color: $e-black;
  font-size: 27px;
  margin: 16px 0 24px;
}
