@import '~style-assets/colors';
@import '~style-assets/fontMixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  background-color: $e-white;
  color: $e-black;

  h4 {
    color: $e-black;
  }
}
