@import '~style-assets/colors';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  color: $e-black;

  // selector for all divs inside aside element in closed asset
  @include for-tablet-portrait-down {
    & > div:first-of-type > div:first-of-type > div:nth-child(3) div:not(:last-child) {
      width: 80%;
    }
  }

  @include for-phone-only {
    & > div:first-of-type > div:first-of-type > div:nth-child(3) div:not(:last-child) {
      width: 90%;
    }
  }
}
