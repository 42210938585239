@import '~style-assets/colors.scss';

.container {
  width: 570px;
  margin-top: 60px;
}

.row,
.title {
  width: 100%;
  color: $e-black;
}

.title {
  font-size: 17px;
  letter-spacing: 1px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.title {
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid #9c9da0;
}
