@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
  @include helvetica-regular;
  width: 720px;
  margin: 0 auto;
  min-height: 700px;
  color: $e-black;

  @include for-tablet-landscape-down {
    padding-top: 16px;
  }

  @include for-768-down {
    width: 100vw;
    padding-top: 0;
  }
}

.top {
  background: $white;
  padding: 12px 35px 0;
  margin-top: 7px;

  @include for-phone-only {
    padding: 12px;
  }

  .row:first-child {
    align-items: center;
    position: relative;
    margin-bottom: 16px;
  }

  .row:nth-child(2) {
    margin-top: 35px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.img {
  width: 135px;
  height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 4px 5px $gray;
  position: relative;

  .icon {
    display: inline-block;
    width: 24px;
    height: 21px;
    position: absolute;
    right: 0;
    bottom: 13px;
    cursor: pointer;
  }

  img {
    width: 70%;
  }
}

.name {
  @include helvetica-medium;
  font-size: 27px;
  text-transform: capitalize;

  word-break: break-word;
  padding-right: 24px;

  @include for-phone-only {
    font-size: 18px;
    padding-right: 0;
  }
}

.info {
  i {
    position: absolute;
    top: -19px;
    right: 0;
    cursor: pointer;
  }
}

.phone,
.email {
  p:first-child {
    @include helvetica-medium;
    margin-bottom: 0;
  }
}

.bottom {
  background-color: $white;
  margin-bottom: 30px;

  @include for-phone-only {
    margin-bottom: 0;
  }

  :global {
    .ant-tabs-nav-wrap {
      padding-left: 35px;
    }
    .ant-tabs-nav .ant-tabs-tab {
      padding-left: 4px;
      padding-right: 4px;
    }

    .ant-tabs-nav .ant-tabs-tab-active {
      @include helvetica-medium;
      position: relative;
      border-bottom: 3px solid $orange;
      transition: background-color 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
      left: 0;
    }

    .ant-tabs-top .ant-tabs-ink-bar-animated {
      display: none !important;
    }

    .ant-tabs-top-bar {
      text-align: left;
      margin-bottom: 0;
      border-bottom: 9px solid $e-white;
    }
  }
}

.half {
  display: flex;

  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
}

.bio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include for-phone-only {
    flex-direction: column;
  }

  .email {
    @include helvetica-medium;
  }
}
