@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/forms';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $e-white;
}
