@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    color: $e-black;
    width: 100vw;
    @include helvetica-regular;
}

.content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 900px;
    width: 100%;

    @include for-phone-only {
        padding: 0 16px;
    }

    h2 {
        @include helvetica-regular;

        @include for-tablet-landscape-down {
            margin-left: 40px;
        }

        @include for-768-down {
            margin: 16px auto 0;
        }
    }
}

.title {
    display: flex;
    font-size: 48px;
    @include helvetica-bold;
    height: 192px;
    background-color: $e-white;
    width: 100%;
    align-items: center;
    justify-content: center;

    @include for-tablet-landscape-down {
        height: 120px;
    }
}

.divider {
    width: 72px;
    height: 1px;
    background-color: $orange;
    display: block;
    margin-top: 45px;
    align-self: center;

    @include for-tablet-portrait-down {
        margin-top: 25px;
    }
}

.subtitle {
    font-size: 16px;
    @include helvetica-medium;
    margin: 50px 0 20px;
    text-align: center;
    padding: 0 90px;
    position: relative;

    @include for-tablet-landscape-down {
        padding: 0 45px;
        margin-top: 20px;
    }

    @include for-phone-only {
        padding: 0 20px;
    }
}

.heading {
    font-size: 27px;
    @include helvetica-medium;
    line-height: 31px;
    margin-top: 40px;
    margin-bottom: 24px;
    padding-bottom: 11px;
    border-bottom: 1px solid $orange;
    width: 100%;

    @include for-tablet-landscape-down {
        width: 90%;
        margin: 20px auto;
    }

    @include for-tablet-portrait-down {
        text-align: center;
    }
}

.row {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 900px;
    padding-top: 27px;
    max-height: 230px;
    border-bottom: 1px solid $e-white;

    @include for-tablet-landscape-down {
        width: 90%;
        margin: 0 auto 0;
        padding-bottom: 20px;
        max-height: initial;
    }

    @include for-tablet-portrait-down {
        flex-direction: column;
    }

    .right {
        width: 440px;
        margin-left: 100px;
        flex: 1;
        font-size: 16px;
        @include helvetica-regular;

        @include for-tablet-landscape-down {
            margin-left: 0;
        }

        @include for-phone-only {
            width: 100%;
        }
    }
}

.lastRow {
    border-bottom: none;
}

.imgContainer {
    flex: 1;
}

.image {
    max-height: 90px;
    max-width: 100%;

    @include for-tablet-landscape-down {
        max-width: 80%;
    }

    @include for-tablet-portrait-down {
        display: block;
        margin: 0 auto 30px;
        max-width: 70%;
    }
}

.contact {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 96px;
    font-size: 34px;
    line-height: 1;
    margin-top: 100px;
    margin-bottom: 83px;

    @include for-phone-only {
        text-align: center;
        height: unset;
    }

    &>span {
        @include helvetica-medium;
    }

    .subtext {
        width: 600px;
        margin-top: 20px;
        margin-left: 100px;
        flex: 1;
        font-size: 16px;
        @include helvetica-regular;

        @include for-tablet-portrait-down {
            width: 100%;
            margin-left: 0;
            text-align: center;
            line-height: 1.5;
        }
    }

    .btn {
        text-transform: uppercase;
        border-radius: 18px;
        color: $e-white;
        background-color: $orange;
        font-size: 14px;
        text-align: center;
        height: 35px;
        width: 152px;
        margin: 10px auto;

        @include for-phone-only {
            margin: 20px auto;
        }

        &:hover,
        &:focus {
            background-color: $orange;
            color: $e-white;
            border-color: transparent;
            box-shadow: 0 3px 2px 0 $dove-gray;
        }

        &:active {
            background-color: $orange;
            color: $e-white;
            bottom: -2px;
        }
    }
}

.cardContainer {
    cursor: pointer;
    height: 300px;
    perspective: 600px;
    position: relative;
    width: 40%; //

    @include for-768-down {
        max-width: 460px;
        margin-bottom: 24px;
        height: 200px;
        // width: 80vw;
    }

    @include for-phone-only {
        height: 300px;
    }

    &:hover {
        .card {
            transform: rotateY(180deg);
        }
    }
}

.card {
    height: 300px;
    position: absolute;
    transform-style: preserve-3d;
    transition: all 0.5s ease-in-out;
    width: 100%;

    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    @include for-768-down {
        height: 200px;
    }

    @include for-phone-only {
        height: 300px;
    }
}

.card .side {
    backface-visibility: hidden;
    border-radius: 6px;
    height: 100%;
    position: absolute;
    overflow: hidden;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .advisorLogo {
        width: 90%;

        @include for-768-down {
            width: 200px;
            max-height: 200px;
        }
    }
}

.card .back {
    background-color: $e-black;
    color: $e-white;
    text-align: center;
    transform: rotateY(180deg);
    height: 100%;
    flex-direction: column;
    padding: 15px;

    @include for-phone-only {
        padding: 10px;
    }

    p {
        color: $e-white;
        width: 100%;
    }

    p:last-of-type {

        @include for-tablet-landscape-down {
            display: none;
        }
    }

    strong {
        font-size: 20px;
        width: 100%;

        @include for-tablet-landscape-down {
            font-size: 18px;
        }

        @include for-phone-only {
            font-size: 14px;
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $orange;
            margin: 5px 0;
        }
    }
}

.rowContainer {
    max-height: 830px;
    justify-content: space-between;

    @include for-768-down {
        flex-direction: column;
        max-height: unset;
    }
}

.teamContainer {
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: none;

    @include for-768-down {
        flex-direction: row;
    }

    @include for-phone-only {
        width: 100%;
    }

    .cardContainer {
        height: 360px; //270px;
        margin-right: 5%;
        margin-bottom: 5%; //rajouté

        @include for-tablet-landscape-down {
            height: 220px;
        }

        @include for-768-down {
            height: 180px;

            max-height: initial;
        }

        @media screen and (max-width: 650px) {
            height: 130px;
        }

        @include for-phone-only {
            height: 100px;
        }
    }

    .cardContainer:nth-child(2n) {
        //rajouté
        margin-right: 0;
    }

    .card {
        height: 360px; //270px;

        @include for-tablet-landscape-down {
            min-width: 210px;
            max-height: 210px;
        }

        @include for-768-down {
            width: 95%;
            min-width: 150px;
            max-height: 190px;
        }

        @media screen and (max-width: 650px) {
            min-width: 120px;
            max-height: 130px;
        }

        @include for-phone-only {
            min-width: 100px;
            max-height: 100px;
        }

        strong {
            @include for-phone-only {
                font-weight: normal;
            }

            a {
                color: $e-white;

                &:hover {
                    color: $orange;
                }
            }
        }
    }

    .memberImg {
        width: 100% !important;
    }
}

.icon {
    margin: 7px;
    height: 30px;
    width: 30px;
    filter: grayscale(1);

    &:hover {
        filter: grayscale(0);
    }

    @include for-phone-only {
        height: 20px;
        width: 20px;
    }
}