@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include helvetica-regular;
}

.header {
  font-size: 12px;
  position: relative;
  margin: 10px;

  &:after {
    width: 120%;
    height: 1px;
    content: '';
    position: absolute;
    background-color: $dove-gray;
    bottom: -1px;
    left: -10%;
  }
}

.title {
  letter-spacing: 1px;
  font-size: 27px;
  @include helvetica-medium;

  @include for-768-down {
    font-size: 18px;
    padding: 0 16px;
  }

  span {
    display: block;
  }
}

.body {
  margin: 20px;

  @include for-phone-only {
    max-width: 100%;
  }

  form {
    margin: auto;
  }
}
