@import '~style-assets/colors';
@import '~style-assets/media-query-points';
@import '~style-assets/breakpoints.scss';
@import '~style-assets/fontMixins.scss';

.container {
  background-color: $e-black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  color: $e-white;
  z-index: 1050;

  @include helvetica-regular;

  button {
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}

.text {
  text-align: center;
  padding: 0 20px;

  @include for-phone-only {
    text-align: left;
  }

  p {
    color: $e-white;

    @include for-phone-only {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: $min-m-xs) {
  .container {
    button,
    button:focus,
    button:hover {
      width: 300px;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: $min-m) {
  .container {
    flex-direction: row;
    padding: 37px 0 20px;

    button,
    button:hover,
    button:focus {
      margin: 10px 10px 10px 79px !important;
      width: 220px;
    }
  }

  .text {
    text-align: left;
  }
}

.signup {
  .container {
    width: 45%;

    @include for-768-down {
      width: 100%;
    }

    button {
      margin: 10px !important;
      align-self: center;
      max-width: 200px;
    }
  }
}

.cookieText {
  white-space: pre-wrap;

  p {
    @include for-phone-only {
      margin-left: 0;
    }
  }
}
