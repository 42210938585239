@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.bar {
  height: 12px;
  width: 100%;
  background: $white;
  margin: 15px 0;
  position: relative;
  display: flex;
  color: $e-white;
  top: 12px;

  .fill {
    height: 100%;
    min-width: 10%;
    max-width: 100%;
    display: flex;
    background: $green;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 4px;
    line-height: 1;

    @include helvetica-medium;
    font-size: 14px;
  }

  .financedAmount {
    position: absolute;
    color: $e-black;
    top: -24px;
    font-size: 14px;
  }
}
