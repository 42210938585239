@import '~style-assets/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 10px;
  position: relative;
  margin: 10px;

  &:after {
    width: 120%;
    height: 1px;
    content: '';
    position: absolute;
    background-color: $dove-gray;
    bottom: -1px;
    left: -10%;
  }
}

.title {
  letter-spacing: 1px;
  font-size: 25px;
}

.body {
  margin: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.button,
.button:hover,
.button:focus {
  background-color: transparent;
  color: $orange;
  border-color: transparent;
  border-radius: 30px;
  height: 36px;
  text-transform: uppercase;
  margin: 10px;
  letter-spacing: 1px;
  transition: none;
}

.button:hover {
  border-color: $e-black;
  color: $e-black;
  transition: none;
}

.button:focus {
  background-color: $orange;
  color: $e-white;
  border-color: $orange;
}

.button:active {
  border-color: transparent;
}

.back {
  @extend .button;
  border: none;
  position: relative;
  color: $e-black;

  &:after {
    position: absolute;
    content: '';
    width: 80%;
    left: 10%;
    bottom: 1px;
    height: 1px;
    background-color: $e-black;
  }

  &:hover,
  &:focus {
    background: none;
    color: $orange;

    &:after {
      background-color: $orange;
    }
  }

  &:active {
    color: $e-black;

    &:after {
      background-color: $e-black;
    }
  }
}

.next {
  @extend .button;

  background-color: transparent;
  color: $orange;
  border-color: $orange !important;

  &:hover,
  &:focus {
    background-color: $orange;
    color: $white;
    border-color: transparent;
    box-shadow: 0 3px 2px 0 $dove-gray;
  }

  &:active {
    bottom: -2px;
  }

  &:disabled {
    border-color: $e-white !important;
  }
}

.submit {
  @extend .button;
  background-color: $orange;
  color: $e-white;

  &:hover,
  &:focus {
    background-color: $white;
    color: $orange;
    border-color: $orange;
    box-shadow: 0 3px 2px 0 $e-white;
  }

  &:active {
    bottom: -2px;
  }
}

.cancel {
  @extend .button;

  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $e-black;
  color: $e-black;
  min-width: 50px;

  &:hover,
  &:active,
  &:focus {
    color: $orange;
    background-color: transparent;
    border-radius: 0;
    border-bottom: 1px solid $orange;
    box-shadow: none;
    min-width: 50px;
  }
}
