@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

:global {
  .ant-form-item-label {
    line-height: 1.5;
    font-weight: 500;
  }

  .ant-slider-track {
    background-color: $e-black;
  }

  .ant-tooltip-placement-bottom {
    padding-top: 0;
  }

  .ant-slider-dot-active {
    display: none;
  }

  .ant-slider-handle.ant-tooltip-open {
    border-color: $orange;
    background-color: $orange;
  }

  .ant-slider:hover .ant-slider-track {
    background-color: $orange;
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background-color: $white;
    box-shadow: none;
    color: $orange;
    margin: 0 8px;
    padding: 0;
    min-height: unset;

    font-size: 12px;
    @include helvetica-regular;
  }
}

.top {
  min-width: 380px;
  background-color: $e-black;
  padding: 15px 30px 12px;
  font-size: 16px;
  color: $e-black;

  @include helvetica-regular;

  @include for-phone-only {
    min-width: unset;
    padding: 15px 15px 12px;
  }
}

h4 {
  color: $e-white;
  text-align-last: left;
  margin-bottom: 0;
  @include helvetica-regular;
}

.info {
  background-color: $e-white;
  padding: 0 17px;

  @include for-phone-only {
    padding: 0;
  }
}

.title {
  font-size: 27px;
  padding-top: 10px;
  margin-bottom: 0;
  @include helvetica-bold;
}

.location {
  font-size: 16px;
  margin-bottom: 10px;
}

.divider {
  height: 1px;
  width: 100%;
  margin: 0 auto 12px;
  background-color: $e-black;
}

.estimation {
  display: flex;
  justify-content: space-between;

  @include for-phone-only {
    width: 280px;
  }

  p {
    margin-bottom: 6px;
    text-align: left;

    @include for-phone-only {
      font-size: 14px;
    }
  }
}

.form {
  background-color: $white;
  padding: 20px 20px 0;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @include for-phone-only {
    min-width: 320px;
    width: 100%;
  }

  label {
    font-size: 16px;
  }
}

.label {
  font-size: 16px;
  margin-bottom: 0;
  text-align-last: left;
  color: $e-black;
  font-weight: 500;

  @include for-phone-only {
    width: 100%;
    margin: 0;
  }

  & + div {
    margin-top: 8px;
  }
}

.input {
  text-align: left;
  width: 100%;
  margin: 0;
}

.sInput {
  @extend .input;
  width: 45%;
  margin-right: 10%;
  display: inline-block;

  &:nth-child(2n) {
    margin-right: 0;
  }
}
