@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/forms';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $e-white;
  min-height: 100vh;
}

.header {
  background-color: #fff;
  width: 100%;
  padding: 8px 25%;
  margin-bottom: 24px;
  box-shadow: rgba($color: #000, $alpha: 0.16) 0 3px 6px;

  @include for-tablet-landscape-down {
    padding: 8px 15%;
  }

  @include for-tablet-portrait-down {
    padding: 8px 5%;
  }

  @include for-phone-only {
    padding: 8px 0;
  }
}

.modalTitle {
  color: #f8c37c;
  @include helvetica-medium;
}

.formWapper {
  @include for-phone-only {
    max-width: 100%;
  }
}
