@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.header {
  color: $e-black;
  @include helvetica-bold;
}

.form {
  @include helvetica-regular;
  background-color: $white;
  padding: 0 20px;
  width: 100%;
  color: $e-black;

  @include for-tablet-landscape-down {
    padding-top: 10px;
  }

  @include for-768-down {
    margin-bottom: 4px;
    padding-left: 0;
  }

  @include for-tablet-portrait-down {
    padding: 0 20px;
  }

  @include for-phone-only {
    padding: 0;
  }

  :global {
    .ant-form-item {
      margin-bottom: 6px;

      @include for-tablet-landscape-down {
        margin-bottom: 4px;
      }
    }

    .ant-form-item-children {
      input {
        padding: 0 11px;
        width: 100%;
      }
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .ant-radio-group {
      display: flex;
      justify-content: space-between;
    }

    .ant-radio-wrapper {
      display: block;
      color: $e-black;
      margin-top: 13px;
      font-size: 14px;
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: baseline;
      color: $e-black;

      span {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  label {
    font-size: 16px;

    @include for-768-down {
      font-size: 14px;
    }

    &::after {
      content: '' !important;
    }
  }
}

.input {
  margin: 0;
  text-align: left;
  width: 100%;

  &__flex {
    display: flex;

    .input__terms {
      color: $orange;
      margin-top: 2px;

      &:hover {
        cursor: pointer;
      }
    }

    :global {
      .ant-col {
        width: 100%;
      }
      .ant-form-item-children {
        display: flex;
        align-items: flex-start;
      }
    }
  }

  @include for-phone-only {
    width: 90%;
    margin: 0 auto;
  }

  :global {
    .ant-form-item-required::before {
      display: none;
    }

    .ant-form-item {
      margin-bottom: 18px;
    }

    .ant-input {
      height: 24px;
    }
  }
}

.phoneInput {
  @extend .input;

  input {
    padding-left: 48px !important;
  }
}

.underline {
  background-color: $orange;
  height: 1px;
  width: 32px;
  margin-bottom: 12px;

  @include for-phone-only {
    margin-left: 5%;
  }
}

p {
  @include for-phone-only {
    width: 90%;
    margin: 0 auto;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray;
  display: block;
  margin-bottom: 14px;
}

.actions {
  display: flex;
  justify-content: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;

  width: 100%;
  max-width: 380px;
  margin-top: 30px;

  @include for-tablet-portrait-down {
    margin-bottom: 10px;
  }

  .divider {
    width: 42px;
  }

  a {
    color: $orange;
  }
}

.termsModal {
  width: 90% !important;
  max-width: 1024px;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  :global {
    .ant-modal-body {
      padding: 24px 0;
    }

    .ant-modal-confirm-title {
      text-align: center;
      font-size: 27px;
      @include helvetica-medium;
      margin-bottom: 20px;
    }

    .ant-modal-confirm-body-wrapper {
      max-height: 70vh;
      overflow: auto;
    }

    .ant-modal-confirm-content {
      padding: 0 24px;
    }
  }
}

.width320 {
  width: 320px !important;
}

.required {
  color: $orange;
  font-size: 10px;
  display: none;
}

.optional {
  @include helvetica-regular;
  margin-left: auto;
  font-size: 12px;
}

.optionalLabel {
  :global {
    .ant-form-item-label {
      width: 100%;
      label {
        display: flex;

        &::after {
          display: none;
        }
      }
    }
  }
}

.container {
  width: 100%;
}
