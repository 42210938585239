@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/forms';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 120px;
}

.qr {
  margin-top: 24px;
}

.form {
  width: 240px;
}