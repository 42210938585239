@import '~style-assets/colors';

.column {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 268px;
  height: 268px;
  border: 2px solid $dove-gray;
  cursor: pointer;
  color: $e-black;

  &:hover {
    color: $e-black;
    background-color: $mercury;
  }
}

.content {
  font-size: 160px;
  padding-bottom: 20px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  border: 1px solid;
  font-weight: bold;
  height: 23px;
  width: 23px;

  &:hover {
    background-color: $e-black;
    color: $e-white;
  }
}

.tooltip {
  p {
    color: $orange;
    font-size: 12px;
    padding: 2px 10px;
  }
}
