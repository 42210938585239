@import '~style-assets/colors';
@import '~style-assets/breakpoints.scss';
@import '~style-assets/fontMixins.scss';

.container {
  width: 100%;
  margin: 10px 0;
}

.step {
  border-bottom: 2px solid $gray;
  display: inline-block;
  width: 20%;
  padding: 8px;
  position: relative;
  transition: all 0.3s;

  @include helvetica-regular;

  @include for-phone-only {
    padding: 8px 0;
    font-size: 13px;
  }

  i {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    color: transparent;
    font-size: 14px;
    background-color: transparent;
  }

  &.current {
    border-color: $yellow;
  }

  &.passed {
    border-color: $green;

    i {
      color: $green;
      background-color: $white;
    }
  }
}

.signup {
  max-width: 380px;
  text-align: center;

  .step {
    width: 50%;
    font-size: 16px;
  }
}

.editAssetStepper {
  display: flex;
  justify-content: center;

  .step {
    text-align: center;
  }
}
