@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  color: $e-black;
  margin-bottom: 100px;
  width: 100vw;
  @include helvetica-regular;
}

.title {
  display: flex;
  font-size: 38px;
  @include helvetica-medium;
  height: 192px;
  background-color: #f2f1f1;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include for-tablet-landscape-down {
    height: 120px;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  padding-top: 20px;

  @include for-tablet-landscape-down {
    margin: 0 20px;
    width: 90%;
  }
}

.cookies {
  font-size: 27px;
  line-height: 31px;
  margin-top: 85px;
  margin-bottom: 24px;

  @include for-tablet-landscape-down {
    padding: 0 45px;
    margin-top: 20px;
  }
}
.devider {
  width: 100%;
  max-width: 720px;
  height: 1px;
  background-color: $orange;
  margin-bottom: 30px;
}

.text {
  p {
    margin-bottom: 16px;
  }
}

.center {
  text-align: center;
}
