@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fontMixins';

.container {
  position: relative;
  margin-top: 20px;
  padding-top: 33px;
  border-top: 1px solid $gray;
  width: 100%;
}

.controls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 10px;

  canvas {
    width: 100%;
  }
}

.header {
  @include helvetica-bold;

  font-size: 15px;
  color: $black;
}

.tabs {
  @include helvetica-bold;

  font-size: 15px;
  color: $black;
}

:global {
  .ant-tabs-bar {
    margin: 0;
    border-bottom: none;
  }
  .ant-tabs-nav {
    margin: 0;

    .ant-tabs-tab {
      @include helvetica-regular;
      color: $gray;
      font-size: 14px;
      letter-spacing: 0.42px;
      margin: 0;
      padding: 5px 8px;
    }
    .ant-tabs-tab-active {
      color: $orange;
    }
  }
}
