$white:                     #ffffff;
$black:                     #000000;
$orange:                    #00AC62;
$green:                     #00AC62;
$blue:                      #4B98C1;
$gray:                      #C2C1C1;
$mercury:                   #E8E8E8;
$yellow:                    #F7BC6C;
$dove-gray:                 #666666;
$red:                       #D12839;
$e-white:                   #F2F1F1;
$e-black:                   #262626;
$e-gray:                    #A09FA0;
