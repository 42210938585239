@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';

.form {
  background-color: $white;
  padding: 20px;
  width: 400px;
}

.input {
  margin: 0;
  text-align: left;
  width: 100%;
}

.tabs {
  background-color: $white;
  @include helvetica-regular;

  div[role='tab'] {
    margin-bottom: 0 !important;
  }
}

.container {
  :global {
    .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
      left: 1px;
      right: unset;
    }

    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
      text-align: left;
      color: $e-black;
      position: relative;
      min-width: 160px;

    }
    .ant-tabs-nav .ant-tabs-tab-active {
      background-color: $e-black;
      color: $e-white !important;

      &::after {
        content: '>';
        position: absolute;
        right: 8px;
        color: $orange;
        font-size: 16px;
      }
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
      color: $orange;
    }
  }
}
