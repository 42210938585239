@import '~style-assets/colors';

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $e-black;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: white;
  box-sizing: border-box;
  min-width: 100%;

  padding-top: 108px;

  @media screen and (max-width: 768px) {
    padding-top: 60px;
  }
}

.body {
  min-height: calc(100vh - 108px);
}

.resetPassword {
  min-height: max-content;
}
