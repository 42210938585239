@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 35px 10px;
  color: $e-black;
  border-bottom: 9px solid $e-white;

  @include for-768-down {
    align-items: center;
  }

  @include for-tablet-portrait-down {
    padding: 28px 12px 10px;
  }

  h3 {
    margin: 0 0 30px;
    font-size: 18px;
    @include helvetica-medium;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include for-768-down {
    flex-wrap: wrap;
  }

  @include for-phone-only {
    justify-content: center;
  }

  div {
    @include for-768-down {
      text-align: center;
      width: 50%;
    }

    @include for-tablet-portrait-down {
      width: 40%;
    }

    @include for-phone-only {
      width: 45%;
    }
  }
}

.title {
  font-size: 16px;
  margin-bottom: 0;
}

.name {
  text-transform: capitalize;
  font-size: 16px;
  word-break: break-word;

  @include helvetica-medium;

  @include for-768-down {
    margin-bottom: 16px;
  }
}

.button,
.button:focus,
.button:active,
.button:hover {
  padding: 0;
  margin: 0;
  font-size: 14px;
  height: 30px;
  width: 250px;
  text-transform: initial;
  @include helvetica-regular;
  background-color: $white;
  color: $e-black;
  border-color: $dove-gray;

  align-self: auto;

  @include for-768-down {
    margin-bottom: 16px;
  }

  @include for-phone-only {
    width: 240px;
  }
}

.lastName {
  max-width: 24%;

  @include for-phone-only {
    max-width: unset;
  }
}
