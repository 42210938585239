@import './fonts';
@import './forms';
@import './icons';
@import './colors';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

body {
  :global {
    div#root {
      min-height: 100%;
      overflow: hidden;
      display: flex;
    }

    .ant-spin-dot-item {
      background-color: $orange;
    }

    .ant-pagination-next,
    .ant-pagination-prev {
      &:hover {
        i {
          color: $orange;
        }
      }
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $orange;
    }

    .ant-radio-inner::after {
      background-color: $orange;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: $orange;
        background-color: $orange;
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $orange;
    }
  }
}
