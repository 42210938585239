@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  align-items: flex-start;
  color: $e-black;

  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }

  @include for-phone-only {
    text-align: center;
  }

  :global {
    .ant-form-item-label {
      margin-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  p {
    margin-bottom: 10px;
  }

  form {
    padding: 0;
    margin-bottom: 0;
    display: block;

    @include for-768-down {
      width: 100%;
      flex-direction: column;
    }

    input {
      min-width: 280px;
      width: auto;
      padding-left: 0;

      @media only screen and (max-width: 550px) {
        min-width: 320px;
      }

      @include for-phone-only {
        min-width: 220px;
        width: 100%;
      }
    }

    button,
    button:focus,
    button:active,
    button:hover {
      margin-left: 0;
    }
  }
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row {
  @include for-phone-only {
    width: 100%;
  }
}

.block {
  width: 50%;
  padding: 28px 24px 35px 35px;

  &:first-child {
    border-right: 9px solid $e-white;

    @media only screen and (max-width: 550px) {
      border-right: none;
      border-bottom: 9px solid #f2f1f1;
    }
  }

  @media only screen and (max-width: 550px) {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    padding: 0;
    margin-bottom: 22px;
    font-size: 18px;
    @include helvetica-medium;

    @media only screen and (max-width: 550px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  form {
    min-width: 220px !important;

    @media only screen and (max-width: 550px) {
      margin: auto;
      max-width: 320px;
    }
  }
}

.heading {
  color: $e-black;
  font-size: 16px;
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.btn {
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  padding: 0;

  &:hover {
    border: 1px solid $e-black;
    @include helvetica-medium;
  }
}

.delBtn {
  color: $orange;

  &:hover {
    border: 1px solid $orange;
  }
}
