@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

:global {
  .ant-tabs-left-bar .ant-tabs-tab {
    font-size: 14px;
    color: $gray;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $e-black;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: $e-black;
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  color: $e-black;
  width: 100vw;
  @include helvetica-regular;

  ::global {
    .ant-tabs {
      height: max-content !important;
    }
  }
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
}

.title {
  display: flex;
  font-size: 48px;
  @include helvetica-medium;
  height: 192px;
  background-color: #f2f1f1;
  width: 100%;
  align-items: center;
  justify-content: center;

  @include for-tablet-landscape-down {
    height: 120px;
  }
}

.divider {
  width: 72px;
  height: 1px;
  background-color: $orange;
  display: block;
  margin-top: 45px;
  align-self: center;

  @include for-tablet-portrait-down {
    margin-top: 25px;
  }
}

.subtitle {
  font-size: 14px;
  @include helvetica-medium;
  margin: 50px 0 20px;
  text-align: center;
  padding: 0 90px;
  position: relative;

  @include for-tablet-landscape-down {
    padding: 0 45px;
    margin-top: 20px;
  }

  @include for-phone-only {
    padding: 0 20px;
  }
}

.heading {
  font-size: 27px;
  line-height: 31px;
  margin-top: 40px;
  margin-bottom: 24px;
  padding-bottom: 11px;
  border-bottom: 1px solid $orange;
  width: 100%;

  @include for-tablet-landscape-down {
    width: 90%;
    margin: 20px auto;
  }

  @include for-tablet-portrait-down {
    text-align: center;
  }
}

.row {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 900px;
  padding-top: 27px;
  padding-bottom: 50px;
  max-height: 230px;
  border-bottom: 1px solid $gray;

  @include for-tablet-landscape-down {
    width: 90%;
    margin: 0 auto 0;
    padding-bottom: 20px;
    max-height: initial;
  }

  @include for-tablet-portrait-down {
    flex-direction: column;
  }

  .right {
    width: 440px;
    margin-left: 100px;
    flex: 1;
    font-size: 16px;
    @include helvetica-regular;

    @include for-tablet-landscape-down {
      margin-left: 0;
    }

    @include for-phone-only {
      width: 100%;
    }
  }
}

.lastRow {
  border-bottom: none;
}

.imgContainer {
  flex: 1;
}
.image {
  max-height: 140px;
  max-width: 100%;

  @include for-tablet-landscape-down {
    max-width: 80%;
  }

  @include for-tablet-portrait-down {
    display: block;
    margin: 0 auto 30px;
    max-width: 70%;
  }
}

.contact {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  font-size: 27px;
  line-height: 1;
  margin-top: 100px;
  margin-bottom: 83px;

  & > span {
    margin-right: 12px;
  }

  .subtext {
    width: 600px;
    margin-top: 20px;
    margin-left: 100px;
    flex: 1;
    font-size: 16px;
    @include helvetica-regular;

    @include for-tablet-portrait-down {
      width: 100%;
      margin-left: 0;
      text-align: center;
      line-height: 1.5;
    }
  }

  .btn {
    margin-top: 20px;
    padding: 0 27px;
    @include helvetica-regular;

    &:hover {
      border-color: $orange;
    }
  }
}

:global {
  .ant-tabs-top-bar {
    text-align: center;
  }

  .ant-tabs-ink-bar {
    background-color: $orange;
  }

  .ant-tabs-nav .ant-tabs-tab {
    @include for-phone-only {
      padding: 8px 4px;
      margin: 0 12px 0 0;
    }
  }

  .ant-tabs-ink-bar {
    z-index: auto;
  }
}

.tabs {
  width: 100%;
  max-width: 900px;
  margin-bottom: 100px;

  @include for-tablet-landscape-down {
    width: 95%;
  }

  @include for-tablet-portrait-down {
    margin: auto;
  }
}
