:global {
  .checkmark {
    display: inline-block;
    width: 138px;
    height: 112px;
    background: url('../images/check-mark.svg') no-repeat;
  }

  .square {
    display: inline-block;
    width: 350px;
    height: 350px;
    background: url('../images/square.svg') no-repeat;
  }

  .square-thin {
    display: inline-block;
    width: 584px;
    height: 584px;
    background: url('../images/square-thin.svg') no-repeat;
  }

  .polygon {
    display: inline-block;
    width: 145px;
    height: 123px;
    background: url('../images/polygon.svg') no-repeat;
  }

  .circle {
    display: inline-block;
    width: 402px;
    height: 622px;
    background: url('../images/circle.svg') no-repeat;
  }

  .unlocked {
    display: inline-block;
    width: 19px;
    height: 17px;
    background: url('../images/unlocked.svg') no-repeat;
  }

  .locked {
    display: inline-block;
    width: 11.6px;
    height: 15px;
    background: url('../images/locked-dark.svg') no-repeat;
  }

  .locked-white {
    display: inline-block;
    width: 11.6px;
    height: 15px;
    background: url('../images/locked-white.svg') no-repeat;
  }

  .location {
    display: inline-block;
    width: 8px;
    height: 12px;
    background: url('../images/home/icon_location.svg') no-repeat;
  }

  .modify {
    display: inline-block;
    width: 19px;
    height: 19px;
    background: url('../images/icon_modify-infos.svg') no-repeat;
    
    &:hover {
      filter: invert(10%) sepia(93%) saturate(4514%) hue-rotate(347deg) brightness(124%) contrast(108%);
    }
  }

  .uploadPhoto {
    display: inline-block;
    width: 24px;
    height: 21px;
    background: url('../images/icon_profil-upload-picture.svg') no-repeat;
  }
}
