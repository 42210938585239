@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  color: $e-black;
  margin-bottom: 100px;
  width: 100vw;
}

.title {
  display: flex;
  font-size: 48px;
  @include helvetica-medium;
  height: 192px;
  background-color: #f2f1f1;
  width: 100%;
  align-items: center;
  justify-content: center;

  @include for-tablet-landscape-down {
    height: 120px;
  }

  @media (max-width: 800px) {
    font-size: 38px;
  }

  @include for-tablet-portrait-down {
    text-align: center;
  }

  @include for-tablet-portrait-down {
    font-size: 27px;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 1130px;
  padding: 0 15px;
  width: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include for-tablet-landscape-down {
    width: 95%;
    margin: auto;
  }
}

.header {
  text-align: left;
  font-size: 27px;
  @include helvetica-medium;
  position: relative;
  letter-spacing: 0.6px;
  margin: 27px 0;

  @include for-tablet-portrait-down {
    font-size: 22px;
    text-align: center;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: -7px;
    left: 0;
    height: 1px;
    background-color: $orange;
  }
}

.assets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  font-size: 34px;
  line-height: 1;
  @include helvetica-medium;
  margin-top: 72px;
}

.btn {
  padding: 0 27px;
  @include helvetica-regular;
  font-size: 14px;

  &:hover,
  &:focus {
    border-color: $orange;
    font-size: 14px;
  }
}

.modal {
  text-align: center;
  color: $e-black;
  top: 30%;

  :global {
    .ant-modal-content {
      border-radius: 0;
      box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
    }

    .ant-modal-body {
      padding: 0 0;
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    background-color: $orange;
    width: 100%;
    max-height: 80px;
    padding: 13px 27px;
    margin: 0;
    font-size: 27px;
    line-height: 1.5;
    color: $e-white;
  }

  .text {
    padding: 0 27px;
    margin-top: 20px;
    font-size: 16px;
    text-align: left;
  }

  .footer {
    text-align: right;
  }

  & button[type='button'],
  & button[type='submit'] {
    outline: none;
    margin: 0 10px;
    border-radius: 16px;
  }
}

.buttons {
  margin-top: 30px;
  align-self: flex-end;
  padding: 28px;
}
