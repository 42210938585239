@mixin super-small {
  @media (max-width: 360px) { @content; }
}
@mixin for-phone-only {
  @media (max-width: 460px) { @content; }
}
@mixin for-phone-landscape-down {
  @media (max-width: 530px) { @content; }
}
@mixin for-tablet-portrait-down {
  @media (max-width: 600px) { @content; }
}
@mixin for-768-down {
  @media (max-width: 768px) { @content; }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 900px) { @content; }
}
@mixin for-desktop-down {
  @media (max-width: 1200px) { @content; }
}
@mixin for-middle-desktop-down {
  @media (max-width: 1440px) { @content; }
}
@mixin for-big-desktop-down {
  @media (max-width: 1800px) { @content; }
}
