@import '~style-assets/colors';
@import '~style-assets/fontMixins';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  background-color: $e-white;
  color: $e-black;
  width: 100vw;
  @include helvetica-regular;

  form {
    margin-top: 80px;
    @include helvetica-regular;
    @include for-phone-only {
      width: 100%;
    }
  }
}

.success {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 27px;
    margin-top: 80px;

    @include for-tablet-portrait-down {
      font-size: 22px;
      padding: 0 20px;
      text-align: center;
    }
  }
}

.link {
  color: $orange;
  margin: 30px auto 0;
  font-size: 16px;

  &:hover, &:active {
    color: $e-black;
  }
}
