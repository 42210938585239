@import '~style-assets/colors';

:global {
  .ant-modal-wrap {
    z-index: 2000;
  }

  .ant-modal-close {
    z-index: 11;
    color: $white;
    transition: none;
    width: 25px;
    height: 25px;
    margin: 10px;

    :hover {
      color: $black;
    }

    &-x {
      font-size: 14px;
      width: 25px;
      height: 25px;
      line-height: 25px;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  color: $e-black;
  width: 100vw;
}

.modal {
  text-align: center;
  color: $e-black;
  top: 30%;

  :global {
    .ant-modal-content {
      border-radius: 0;
      box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
    }

    .ant-modal-body {
      padding: 0 0;
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    background-color: $orange;
    width: 100%;
    padding: 13px 27px;
    margin: 0;
    font-size: 27px;
    line-height: 1.5;
    color: $e-white;
  }

  .text {
    padding: 0 27px;
    margin-top: 20px;
    font-size: 16px;
    text-align: left;
  }

  .red {
    color: $orange;
  }

  .footer {
    text-align: right;
  }

  & button[type='button'],
  & button[type='submit'] {
    outline: none;
    margin: 0 10px;
    border-radius: 16px;
  }
}

.buttons {
  margin-top: 30px;
  align-self: flex-end;
  padding: 28px;
}

.spin {
  z-index: 1000;
}

.sliderModal {
  width: 95% !important;
  max-width: 1440px;
  top: 30px;

  :global {
    .ant-modal-content {
      background-color: transparent !important;
    }
  }
}

.notFound {
  background-color: $gray;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;

  p {
    margin: auto;
    font-size: 32px;
  }
}
