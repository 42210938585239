@import '~style-assets/colors';
@import '~style-assets/fontMixins';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: 100vw;
  min-height: calc(100vh - 70px);
  @include helvetica-regular;

  @include for-768-down {
    min-height: calc(100vh - 60px);
  }

  :global {
    .ant-form-item-label {
      line-height: 16px;
      margin-bottom: 8px;
      @include helvetica-medium;

      label {
        font-size: 16px;
      }
    }

    .ant-input {
      border: 1px solid $gray !important;
    }
  }

  @include for-tablet-portrait-down {
    flex-direction: column-reverse;
  }
}

aside {
  box-sizing: border-box;
  width: 45%;
  background-color: $e-white;
  color: $e-black;
  padding: 200px 76px 0;

  @include for-desktop-down {
    padding: 100px 40px 0;
  }

  @include for-tablet-landscape-down {
    padding: 16px 20px 0 20px;
  }

  @include for-tablet-portrait-down {
    width: 100%;
    padding: 30px;
  }

  h3 {
    font-size: 27px;
    line-height: 31px;
    margin-bottom: 24px;
    @include helvetica-medium;

    @include for-tablet-landscape-down {
      text-align: center;
    }
  }

  .devider {
    width: 72px;
    height: 1px;
    background-color: $orange;

    @include for-tablet-landscape-down {
      margin: auto;
    }
  }

  p {
    font-size: 16px;
    line-height: 19px;
    margin-top: 28px;
    margin-bottom: 0;
    padding-left: 44px;
    position: relative;

    .icon {
      position: absolute;
      top: 3px;
      left: 0;
      font-size: 22px;

      svg {
        fill: $green;
      }
    }
  }
}

.content {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  margin-left: 107px;
  padding-top: 30px;

  @include for-tablet-landscape-down {
    margin-left: 40px;
  }

  @include for-768-down {
    padding-top: 0;
  }

  @include for-tablet-portrait-down {
    margin: auto;
  }

  @include for-phone-only {
    width: 100%;
  }

  form {
    max-width: 380px;
    width: 100%;

    @include for-tablet-portrait-down {
      margin: auto;
    }

    @include super-small {
      min-width: 320px;
    }

    h4 {
      color: $e-black;

      @include for-phone-only {
        width: 90%;
        margin: 16px auto 8px;
      }
    }
  }
}

.terms {
  @include helvetica-medium;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.termsModal {
  width: 90% !important;
  max-width: 1024px;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  :global {
    .ant-modal-body {
      padding: 24px 0;
    }

    .ant-modal-confirm-title {
      text-align: center;
      font-size: 27px;
      @include helvetica-medium;
      margin-bottom: 20px;
    }

    .ant-modal-confirm-body-wrapper {
      max-height: 70vh;
      overflow: auto;
    }

    .ant-modal-confirm-content {
      padding: 0 24px;
    }
  }
}
