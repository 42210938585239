@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';

.content {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 60px 0;
  width: 100%;
  max-width: 940px;
  color: $e-black;

  @include helvetica-regular;
}

.title {
  letter-spacing: 1px;
  font-size: 25px;
}

.heading {
  font-size: 16px;
}

.tWrapper {
  flex: 1;
  align-self: stretch;
  padding: 15px;
  position: relative;

  @include helvetica-regular;
  color: $e-black;

  :global {
    .ant-table-body {
      margin: 0 !important;

      td {
        text-align: center;
        padding: 10px 8px !important;
        font-size: 16px;
      }
    }

    table .ant-table-thead tr th {
      background-color: $yellow;
      color: $e-white;
      font-size: 16px;
      padding: 15px 10px 20px !important;
      text-align: center;
    }
  }
}

.inactive {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: inline;
}

:global {
  .ant-table-row:hover {
    :local {
      .inactive {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.container {
  position: absolute;
  bottom: 50px;
  right: 50px;
  display: none;
}

.button {
  background-color: $e-black;
  border-color: $e-black;
}

.table {
  background-color: $white;
  th {
    text-transform: capitalize;
  }
}
