@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/forms';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 60px 0;
}

.title {
  letter-spacing: 1px;
  font-size: 25px;
}
