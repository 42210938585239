@import '~style-assets/colors';
@import '~style-assets/fontMixins';
@import '~style-assets/breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  background-color: $e-white;
  color: $e-black;
  width: 100vw;
  padding-top: 100px;
  min-height: calc(100vh - 70px);

  h3 {
    margin: 40px auto 57px;
    font-size: 48px;
    @include helvetica-medium;

    @include for-tablet-landscape-down {
      margin: 25px;
    }

    @include super-small {
      font-size: 18px;
      margin: 8px;
    }
  }

  form {
    margin-bottom: 30px;
    @include helvetica-regular;

    @include for-phone-only {
      width: 100%;
    }

    @include super-small {
      padding-top: 12px;
    }

    button {
      @include super-small {
        margin: 0;
        height: 30px;
      }
    }
  }
}
