@import "~style-assets/colors";
@import '~style-assets/fontMixins';
@import '~style-assets/forms';

.container {
  position: relative;
  width: 100%;
  background: #ffffff no-repeat center;
  background-size: cover;
  padding: 33px 0 33px 110px;
  overflow: hidden;
  z-index: 0;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $white;
    z-index: 1;
    opacity: .7;
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4; // 3 will be used for elements

    width: 100%;
    max-width: 497px;
    background: $white;
    padding: 33px;
    border-radius: 9.47px;

    @media only screen and (max-width: 1280px) {
      max-width: 350px;
      padding: 10px 15px 15px;
    }
  }

  i {
    position: absolute;
    z-index: 3;
    opacity: .7;
  }
}

.square {
  bottom: 200px;
  left: 480px;

  @media only screen and (max-width: 1280px) {
    left: 30%;
    bottom: 10%;
  }
}

.poly {
  top: 300px;
  right: 110px;

  @media only screen and (max-width: 1280px) {
    top: 10%;
    right: 10%;
  }
}

.angle-circle {
  bottom: -321px;
  right: -107px;
}