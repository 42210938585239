@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 32%;
  min-height: 448px;
  transition: all 0.3s;
  color: $e-black;
  line-height: 1;
  margin-bottom: 16px;
  border: 0.2px solid $mercury;
  position: relative;
  @include helvetica-regular;
  margin-right: 1%;

  @include for-tablet-landscape-down {
    max-width: 49.5%;
    min-width: 280px;
  }

  @include for-tablet-portrait-down {
    margin: auto;
  }

  @include for-phone-only {
    max-width: 95%;
    margin: auto;
  }

  @include super-small {
    margin-bottom: 22px;
  }

  .arrow {
    color: #fff;
    opacity: 0;
    font-size: 30px;
    line-height: 1.5em;
    text-shadow: 2px 2px 2px #252930;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: all 0.3s;
  }

  &.demo {
    box-shadow: rgba($color: #000000, $alpha: 0.1) 4px 2px 15px;
    .imgWrap::before {
      opacity: 0.55;
    }
  }

  &:not(.demo):hover {
    cursor: pointer;
    box-shadow: rgba($color: #000000, $alpha: 0.16) 4px 8px 5px;
    color: $e-black;

    .imgWrap::before {
      opacity: 0.55;
    }
    .arrow {
      opacity: 1;
    }
  }

  a {
    display: flex;
    flex-direction: column;
  }
}

.head {
  background-color: $e-black;
  color: white;
  font-size: 18px;
  @include helvetica-medium;
  padding-left: 12.5px;
  height: 35px;
  line-height: 35px;

  @include for-tablet-portrait-down {
    font-size: 16px;
  }
}

.name {
  font-size: 27px;
  @include baton-regular;
  padding-left: 12px;
  color: $e-black;
  margin-bottom: 5px;

  @include for-tablet-portrait-down {
    font-size: 22px;
  }
}

.type {
  font-size: 27px;
  padding-left: 12.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: $e-black;
  @include baton-bold;

  @include for-tablet-portrait-down {
    font-size: 16px;
  }
}

.subAddress {
  font-size: 27px;
  @include helvetica-regular;
  padding-left: 12.5px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: $e-black;

  @include for-tablet-portrait-down {
    font-size: 22px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray;
  display: block;
}

.location {
  font-size: 18px;
  padding-left: 12.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: $e-black;
  display: flex;
  align-items: center;

  @include helvetica-medium;

  @include for-tablet-portrait-down {
    font-size: 16px;
  }
}

.icon {
  color: $orange;
  margin-right: 6px;
  width: 0.65em;
}

.price {
  font-size: 18px;
  @include helvetica-bold;
  text-transform: uppercase;
  color: $green;
  margin: 0 12.5px;
  padding: 10px 0;
  // border-top: 1px solid $orange;

  @include for-tablet-portrait-down {
    font-size: 16px;
  }
}

.imgWrap {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $e-black;
    opacity: 0;
    transition: all 0.3s;
  }
}

.img {
  width: 100%;
  height: fit-content;
  max-height: 205px;
}

.label {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 8px;
  border-radius: 15px;
  text-transform: uppercase;
  font-size: 12px;
}

.sale {
  @extend .label;
  background-color: $e-white;
  color: $e-black;
}

.ended {
  @extend .label;
  background-color: #7cdbab;
  color: $e-white;
}

.link {
  color: $black;

  &:hover {
    color: $orange !important;
  }
}

.linkButton {
  justify-content: center;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid $gray;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  padding: 0 15px;
}

.footer {
  display: flex;
  margin: 15px 8px 30px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  .half {
    color: $e-black;
    margin: 0 2px;
    flex-direction: column;

    @include for-tablet-landscape-down {
      margin: 0;
    }

    .green {
      color: $green;
      margin-top: 10px;
    }
  }

  .interestRate {
    margin-right: 4px;
    min-width: 30%;
  }
}

.bold {
  @include helvetica-bold;
  margin-right: 3px;
}

.progress {
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  margin: 10px;
}

.description {
  font-size: 12px;
  margin-bottom: 7px;
  color: $e-black;

  display: flex;
  justify-content: space-between;
}

.line {
  width: 100%;
  position: relative;
  height: 11px;
  background-color: $gray;
}

.subline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px;
}

.button {
  width: 100%;
  font-size: 14px;
  @include helvetica-regular;
  margin: 0 12.5px;
  margin-top: 16px;

  &:hover,
  &:focus {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 16px;
  }

  &.disabled {
    background: #f5f5f5;
    pointer-events: none;
    border: 1px solid $gray;
    color: #c2c1c1;
  }
}

@include for-tablet-landscape-down {
  .root {
    width: 48%;
  }
}

@include for-768-down {
  .root {
    width: 100%;
    max-width: 480px;
    margin: 0 auto 30px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
