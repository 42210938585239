@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/media-query-points.scss';
@import '~style-assets/breakpoints.scss';

:global {
  .ant-carousel {
    .slick-dots {
      min-width: 100px !important;

      li button {
        background-color: transparent;
        height: 8px;

        &:hover {
          height: 8px;
          width: 8px;
          border-radius: 50%;
        }
      }
      li.slick-active {
        background-color: $green !important;
        button {
          background-color: transparent;
          width: 8px;
          &:hover {
            height: 8px;
            width: 8px;
            border-radius: 50%;
          }
        }
      }
    }
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }
    .slick-slide {
      & > div {
        height: 100%;
      }
    }
    .slick-arrow {
      margin-top: 0 !important;
      height: 21px !important;
      width: 10px !important;
      &:before {
        opacity: 1 !important;
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 15px;
        position: relative;
        top: 0.15em;
        vertical-align: top;
        width: 15px;
        left: 0;
        transform: rotate(45deg);
        font-size: 24px;
        color: $e-black;
      }
      &.slick-prev {
        &:before {
          transform: rotate(-135deg);
        }
      }
    }

    .slick-next,
    .slick-prev {
      bottom: -67px !important;
      top: unset !important;
    }
  }

  @media screen and (min-width: $min-s) {
    .slick-slide:not(.slick-center) {
      transition: all 0.5s;
    }
  }

  @media screen and (min-width: $min-s) {
    .slick-slide.slick-center {
      transition: all 0.5s;
    }
  }

  .slick-dots {
    height: 8px !important;
    width: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    margin: 0 auto !important;

    .slick-active {
      background-color: red !important;
      height: 8px !important;
    }

    li button {
      width: 8px !important;

      &::before {
        content: '' !important;
      }
    }
  }
}

.carousel {
  width: 100% !important;
  height: 60vh;
  overflow: visible;
  display: flex;
  margin: 0 auto 50px;

  ul {
    bottom: -50px !important;

    li {
      width: 8px;
      height: 8px;
      background-color: $e-black;
      border-radius: 50%;
    }
  }

  p {
    margin: 0;
  }

  @include for-tablet-portrait-down {
    .slick-slide {
      transform: none !important;
    }

    .right,
    .content {
      position: static;

      & > span {
        margin: 15px 0;
      }
    }
  }
}

.container {
  width: 100%;
  height: 100% !important;
  margin: 0 auto;
  padding: 11px;

  &__wrap {
    height: 100%;
  }
}

.imageWrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
  height: 100%;
  box-shadow: 4px 8px 10px -4px $gray;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
}

.button {
  @include helvetica-regular;
  font-size: 14px;
  line-height: 36px !important;
  margin: 0 auto;
  border: 1px solid transparent;
  border-radius: 30px;
  height: 36px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 270px;
  text-align: center;

  &:active {
    bottom: -2px;
  }
}

.sliderWrap {
  position: relative;
}
.clickableArea {
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  max-width: 12%;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: $min-s) {
  .button:hover {
    box-shadow: none;
    border: 1px solid $green;
  }

  .button:focus {
    @include helvetica-regular;
    font-size: 14px;
    line-height: 36px !important;
    margin: 0 auto;
    border-radius: 30px;
    height: 36px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 270px;
  }
}

.cornerButton {
  @include helvetica-medium;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;

  position: absolute;
  background-color: $e-white;
  color: $e-black;
  top: initial;
  left: initial;
  font-size: 11px;
  height: 25px;
  padding: 0 30px;
  border-color: $e-black;
  line-height: 25px;
  width: auto !important;
  margin: 10px;

  &.link:hover {
    color: $green;
  }
}

.successButton {
  background-color: $green;
  border-color: $e-white;
  color: $e-white;
  line-height: 25px;
}

.right {
  width: 100%;
  color: $e-black;
  position: absolute;
  right: 52px;
  max-width: 400px;
  background: #fff;
  bottom: 52px;
  .content {
    position: static;
    padding-bottom: 20px;
  }
}

.header {
  @include helvetica-bold;
  width: 100%;
  background-color: $e-black;
  color: $e-white;
  padding: 4px 15px;
  font-size: 13px;
  margin: 0;
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  max-width: 450px;

  position: absolute;
  left: 40px;
  top: 0;

  @include super-small {
    width: 100%;
  }

  & > span {
    margin: 26px 0;
  }

  & > .button {
    margin: 0 auto;
    width: 100% !important;
    &__disabled {
      background: #f5f5f5;
      pointer-events: none;
      border: 1px solid #d9d9d9;
      color: #c2c1c1;
    }
  }
}

.primaryTitle {
  @include helvetica-bold;
  font-size: 22px;
  margin-top: 14px;
}

.secondaryTitle {
  @include helvetica-bold;
  margin-bottom: 0;
  font-size: 15px;
}

.text {
  @include helvetica-medium;
  font-size: 14px;
  color: $e-black;
}

.address {
  font-size: 13px;
  color: $e-black;
  margin: 0;
  @include helvetica-regular;

  span {
    margin-left: 9px;
  }
}

.underline {
  width: 72px;
  height: 1px;
  background-color: $green;
  display: block;
  margin-left: 0 !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray;
  display: block;
}

.subHeading {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
  @include helvetica-regular;

  @include super-small {
    margin-left: 0;
  }
}

.bar {
  width: 100%;
  height: 11px;
  background: -moz-linear-gradient(
    left,
    #d2d1d1 0%,
    #252930 0%,
    #252930 60%,
    #d2d1d1 61%,
    #252930 61%,
    #d2d1d1 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #d2d1d1 0%,
    #252930 0%,
    #252930 60%,
    #d2d1d1 61%,
    #252930 61%,
    #d2d1d1 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #252930 0%,
    #252930 60%,
    #d2d1d1 60%,
    #d2d1d1 100%
  );
}

.value {
  @include helvetica-bold;
  color: $green;
  text-transform: uppercase;
  font-size: 22px;
  margin-bottom: 0;
  padding: 10px 0 5px 0;

  @include super-small {
    font-size: 16px;
    margin: 0;
  }
}

.info {
  @include helvetica-regular;
  width: 100%;
}

.sale {
  margin: 0 auto;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  p {
    margin-bottom: 0;

    @include super-small {
      margin-left: 0;
    }
  }

  .red {
    color: $green;
  }
}

.expected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  @include super-small {
    button {
      padding: 8px 0;
    }
  }

  div {
    font-size: 16px;
  }

  .verticalDivider {
    display: block;
    width: 1px;
    background-color: $gray;
    height: 30px;
  }

  span {
    color: $green;
  }
}

.financed {
  .value {
    color: $e-black;
  }

  .bar {
    background: $green none;
    margin-bottom: 52px;
  }

  .subHeading {
    strong {
      color: $green;
    }
  }

  button {
    margin-top: 52px;
  }
}

@media screen and (min-width: 320px) {
  .value {
    font-size: 14px;
    padding-top: 5px;
  }

  .sale {
    font-size: 14px;
    padding: 5px 0;
  }

  .expected {
    padding: 5px 0;

    div {
      font-size: 14px;
    }
  }

  .successButton {
    padding: 0 10px;
  }
}

@media screen and (min-width: $min-m-xs) {
  .sale {
    padding: 18px 0;
  }
}

@media screen and (max-width: $max-l-s) {
  .carousel {
    height: 60vh;
    .image {
      max-height: 50%;
    }
  }
}

@media screen and (max-width: $min-m-s) {
  .expected {
    padding: 10px 0;
  }
  .value {
    padding: 5px 0;
  }

  .primaryTitle {
    margin: 0;
  }

  .content > .text {
    margin: 5px 0;
  }
}

@media screen and (min-width: $min-xs) {
  .container {
    min-height: 265px;
  }

  .underline {
    margin: 16px auto 0;
  }

  .primaryTitle {
    margin-top: 14px;
  }

  .right {
    .content {
      padding: 5px 5px 10px;
    }
  }

  .cornerButton {
    height: 30px;
    margin: 10px;
    padding-top: 2px;
  }

  .value {
    font-size: 14px;
    padding-top: 5px;
  }

  .subHeading {
    margin-top: 5px;
  }

  .sale {
    font-size: 14px;
  }

  .expected {
    padding: 5px 0;

    div {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: $min-s) {
  .imageWrapper {
    flex-direction: row;
  }

  .right {
    right: 10px;
    bottom: 10px;
    width: 300px;
    .content {
      padding: 0 15px 20px;
    }
  }

  .secondaryTitle {
    &:first-of-type {
      margin: 20px 0 0;
    }
  }

  .address {
    font-size: 14px;
  }

  .value {
    font-size: 14px;
    padding: 3px 0;
  }

  .sale {
    padding: 3px 0;
  }

  .expected {
    padding: 3px 0;
  }

  .successButton,
  .cornerButton {
    font-size: 10px;
    padding: 0 11px;
  }

  .cornerButton {
    top: 10px;
    left: 10px;
    height: 28px;
    padding: 0 11px;
  }
}

@media screen and (max-width: $max-s) {
  .right,
  .content {
    right: auto;
    left: 0;
    top: auto;
    bottom: 0;
    max-width: 100%;
    width: 100%;
  }
  .right {
    .content {
      padding-bottom: 10px;
    }
  }
}

@media screen and (min-width: $min-sm) {
  .text {
    font-size: 18px;
  }

  .right {
    right: 52px;
    bottom: 52px;
    width: 300px;
    .content {
      padding: 0 15px 20px;
    }
  }

  .address {
    padding: 10px 0;
  }

  .button {
    width: 381px;
    margin-top: 20px;
    font-size: 14px;

    @media screen and (min-width: $min-s) {
      .button:focus,
      .button:hover {
        width: 381px;
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }

  .cornerButton {
    padding: 0 20px;
    line-height: 30px !important;
    margin-top: 0;
    font-size: 10px;
    width: auto;
  }

  .content {
    & > button {
      margin-top: 20px;
      width: 381px;
    }
  }

  .primaryTitle {
    font-size: 36px;
  }

  .secondaryTitle {
    font-size: 27px;
  }

  .value {
    font-size: 18px;
  }

  .sale {
    font-size: 16px;
  }

  .expected {
    div {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: $min-m) {
  .carousel {
    &:hover {
      cursor: grab;
    }
  }
}

@media screen and (min-width: $min-l) {
  .carousel {
    margin: 0 auto 100px;
  }

  .container {
    min-height: initial;
  }

  .right {
    width: 100%;
    .content {
      padding: 0 30px 30px;
    }
  }

  .content {
    padding: 0 24px 0 31px;

    & > button {
      margin: 0 auto;
      width: 381px;
    }
  }

  .primaryTitle {
    margin-top: 52px;
    font-size: 34px;
  }

  .secondaryTitle {
    font-size: 34px;
    margin-left: 0;
    &:last-of-type {
      @include helvetica-regular;
    }
  }

  .text {
    font-size: 27px;
  }

  .button,
  .button:focus,
  .button:hover {
    font-size: 14px;
    height: 36px;
    width: 381px;
    margin: 0 auto;
  }

  .imageWrapper {
    flex-direction: row;
    position: relative;

    .image {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
  }

  .underline {
    margin-top: 24px;
  }

  .successButton,
  .cornerButton {
    font-size: 12px;
    padding: 3px 15px;
  }

  .cornerButton {
    top: 24px;
    left: 35px;
    height: 36px;
    font-size: 12px;
    padding: 3px 15px;
  }

  .header {
    padding: 9px 31px;
    font-size: 18px;
  }

  .address {
    font-size: 18px;
    margin: 10px 0;
  }

  .value {
    font-size: 22px;
    margin-bottom: 0;
    padding: 12px 0;
  }

  .subHeading {
    margin-top: 27px;
  }

  .sale {
    padding: 20px 0 0;
    font-size: 18px;

    p {
      margin-bottom: 1em;

      &:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }

  .expected {
    padding: 20px 0;

    & > div {
      font-size: 18px;
    }
  }

  .verticalDivider {
    height: 30px;
  }
}
