@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.description {
  width: 100%;
  padding-bottom: 30px;
  color: $e-black;
  box-sizing: border-box;

  @include helvetica-regular;

  @include for-tablet-portrait-down {
    padding-top: 0;
  }

  @include for-768-down {
    padding-right: 0;
  }
}

.customHTML {
  color: #252930;
  font-size: 16px;
  p {
    text-indent: 18px;
    margin-bottom: 10px;
  }
  a {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include helvetica-medium;
    text-align: center;
    color: #252930 !important;
    margin-bottom: 12px !important;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }

  hr {
    margin-bottom: 15px;
  }
  ul,
  ol {
  }

  li {
    color: #252930;
    font-size: 16px;
  }
}
