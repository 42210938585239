@import '~style-assets/fontMixins';
@import '~style-assets/colors';

:global {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px 8px;
  }

  .ant-table-pagination.ant-pagination {
    padding-right: 20px;
  }
}

.content {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 60px 0;
  max-width: 940px;
  color: $e-black;

  @include helvetica-regular;
}

p {
  margin-bottom: 0;
}

.title {
  letter-spacing: 1px;
  font-size: 25px;
}

.heading {
  font-size: 16px;
}

.tWrapper {
  box-shadow: 0 4px 5px 0 $gray;

  th {
    border-radius: 0 !important;
  }
}

.tcontainer {
  width: 100%;
  margin-top: 40px;

  table {
    col:nth-child(1),
    col:nth-child(4),
    col:nth-child(5),
    col:nth-child(7) {
      width: 120px;
    }

    col:nth-child(2) {
      width: 126px;
    }

    col:nth-child(3) {
      width: 200px;
      padding: 16px 8px;
    }

    col:nth-child(4) {
      width: 120px;
    }

    col:nth-child(6) {
      width: 100px;
    }

    thead {
      th {
        color: $white;
      }
    }

    tbody {
      background-color: $white;
    }
  }
}

// selector for all tables
.tbl {
  table {
    table-layout: fixed;
    text-align: center;
    color: $e-black;

    thead {
      th {
        padding: 5px 10px;
        text-align-last: left;

        &:nth-child(1)::before {
          padding: 0 5px;
        }
      }
    }
  }
}

.thead {
  display: flex;
  justify-content: space-between;

  div {
    padding: 15px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.thead > div:nth-child(even) {
  background-color: $white;
}

.thead > div:nth-child(1),
.thead > div:nth-child(2),
.thead > div:nth-child(4),
.thead > div:nth-child(5) {
  width: 125px;
}

.thead > div:nth-child(3) {
  width: 200px;
}

.thead > div:nth-child(4) {
  width: 120px;
}

.thead > div:nth-child(6) {
  width: 220px;
}

.tPending {
  border: 1px solid $yellow;

  table {
    thead {
      th {
        background-color: $yellow;
      }
    }

    td {
      border-color: $yellow;
    }
  }
}

.tApproved {
  border: 1px solid $green;

  table {
    thead {
      th {
        background-color: $green;
      }
    }

    td {
      border-color: $green;
    }
  }
}

.tDeclined {
  border: 1px solid $red;

  table {
    thead {
      th {
        background-color: $red;
      }

      td {
        border-color: $red;
      }
    }
  }
}

.number {
  display: inline-block;
  padding: 4px 11px 2px;
  margin-left: 10px;
  background-color: $white;
  border-radius: 8px;
  line-height: 1;
  font-size: 12px;
  cursor: pointer;
}

.button {
  margin-top: 25px;
  background-color: $e-black;
  border-color: $e-black;
  align-self: flex-end;
}

// divider selector
.actions span div {
  margin: 0;
}

.btn {
  font-size: 11px;
  padding: 2px 8px;
  border: none;
  background: none;
  transition: none;
  box-shadow: none;
  cursor: pointer;
  height: 20px;

  &:hover,
  &:focus,
  &:active {
    background: none;
  }

  &:hover {
    font-weight: bold;

    span {
      text-decoration: underline;
    }
  }
}

.download {
  color: $yellow;

  &:hover,
  &:focus,
  &:active {
    span {
      color: $yellow;
    }
  }
}

.send {
  color: $green;

  &:hover,
  &:focus,
  &:active {
    span {
      color: $green;
    }
  }
}

.confirmationBtns {
  display: flex;
  flex-direction: column;
}

.confirm {
  color: $green;
  margin-bottom: 4px;

  &:hover,
  &:focus,
  &:active {
    span {
      color: $green;
    }
  }
}

.decline {
  color: $orange;

  &:hover,
  &:focus,
  &:active {
    span {
      color: $orange;
    }
  }
}

.contact {
  font-size: 14px;
  border-radius: 14px;
  border: 0.5px solid $gray;
  padding: 2px 8px;
  color: $e-black;
  display: block;
  cursor: pointer;

  &:hover {
    color: $orange;
    border-color: $orange;
  }

  &:active {
    color: $e-white;
    background-color: $orange;
    border-color: $orange;
  }

  &:last-child {
    margin-top: 4px;
  }
}

// table footer styles
.showMore {
  cursor: pointer;
  background-color: $white;
}

.Pending {
  color: $yellow;

  &:hover {
    background-color: $yellow;
    color: $white;
  }
}

.Approved {
  color: $green;

  &:hover {
    background-color: $green;
    color: $white;
  }
}

.Declined {
  color: $orange;

  &:hover {
    background-color: $orange;
    color: $white;
  }
}

.confirmationModal {
  @include helvetica-regular;
  text-align: center;
  width: 435px !important;
  box-shadow: 0 4px 5px 0 $e-black;
  padding-bottom: 0;

  h2 {
    font-size: 27px;
    margin-top: 14px;
  }

  div {
    text-align: center;
  }

  :global {
    .ant-modal-close {
      color: $black;
    }
  }
}

.bottom {
  margin-top: 24px;

  div {
    justify-content: center;
  }

  button,
  button:hover,
  button:focus,
  button:active {
    min-width: 120px;
  }
}

.resendEmailModal {
  :global {
    .ant-modal-confirm-btns {
      display: none;
    }
  }

  width: 90% !important;
  max-width: 540px;
}

.disabled {
  &:hover {
    span {
      position: relative;
      color: inherit;
      text-decoration: none;
      font-weight: normal;

      &::before {
        position: absolute;
        content: 'This action cannot be performed, because public key is missing';
        top: -40px;
        left: -180px;

        background: $white;
        color: $orange;
        font-size: 14px;
        padding: 8px;
      }
    }
  }
}

.userName {
  word-break: break-word;
}

.statusIcon {
  margin-right: 5px;
}
