@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/breakpoints.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include helvetica-regular;

  padding: 10px 0 20px;

  @include for-768-down {
    width: auto;
    margin-top: 16px;
  }

  @include for-tablet-portrait-down {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
  }

  a {
    width: 100%;
  }
}

.title {
  color: $e-black;
  margin-bottom: 16px;
  font-size: 18px;
  @include helvetica-medium;
  text-align: center;
  padding: 0 16px;

  @include for-tablet-portrait-down {
    margin-bottom: 8px;
  }

  @include for-phone-only {
    font-size: 16px;
  }

  @include super-small {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.button,
.button:hover {
  text-transform: uppercase;
  padding: 7px 20px;
  width: 90%;
  margin: 0 10px;
}

.icon {
  display: inline-block;
  font-size: 12px;
  border-radius: 50px;
  border: 1px solid $e-black;
  width: 16px;
  height: 16px;
  line-height: 16px;

  &:hover {
    cursor: pointer;
  }
}

.modal {
  text-align: center;
  color: $e-black;
  top: 30%;

  :global {
    .ant-modal-content {
      border-radius: 0;
      box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
    }

    .ant-modal-body {
      padding: 0 0 25px;
    }
  }
  .title {
    background-color: $orange;
    width: 100%;
    padding: 13px 27px;
    margin: 0;
    font-size: 27px;
    line-height: 1.5;
    color: $e-white;
    @include helvetica-medium;
  }

  .text {
    padding: 0 27px;
    margin: 30px auto;
    font-size: 14px;
    line-height: 1.2;
  }

  .footer {
    text-align: right;
    padding: 0 27px;
  }
}

.modalContainer {
  .text {
    font-size: 16px;
    @include helvetica-regular;
    text-align: left;
    line-height: 1.5;
  }
}
