@mixin baton-bold {
  font-family: 'Baton Bold';
}

@mixin baton-book {
  font-family: 'Baton Book';
}

@mixin baton-medium {
  font-family: 'Baton Medium';
}

@mixin baton-regular {
  font-family: 'Baton Regular';
}

@mixin helvetica-bold {
  font-family: 'HelveticaNeue';
  font-weight: 700;
}

@mixin helvetica-medium {
  font-family: 'HelveticaNeue';
  font-weight: 500;
}

@mixin helvetica-regular {
  font-family: 'HelveticaNeue';
  font-weight: 400;
}
