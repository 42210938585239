@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';

.container {
  display: flex;
  justify-content: space-between;
  max-width: 395px;
  flex-wrap: wrap;

  &:not(span) {
    width: 100%;
  }

  :global {
    .ant-upload.ant-upload-drag {
      max-height: 140px;
    }
  }
}

.upload {
  width: 100%;
  margin-bottom: 12px;
}

.arrow {
  width: 37px;
  height: 37px;
  margin: 0 auto 12px !important;
  border: 1px solid $e-black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addImage {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  margin: 20px 0 0 auto;
  background-color: red;
}
