@import '~style-assets/colors';

.form {
  background-color: $white;
  padding: 20px;
  width: 400px;

  :global {
    .ant-select-selection__placeholder {
      display: block !important; // no other way to override ant's display none for select placeholder
    }
  }
}

.input {
  margin: 0;
  text-align: left;
}

.sInput {
  @extend .input;
  width: 45%;
  margin-right: 10%;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.mInput {
  @extend .input;
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
}

.rowEnd {
  @extend .row;
  align-items: flex-end;
}

.rowStart {
  @extend .row;
  align-items: flex-start;
}

.col {
  width: 50%;
  margin-right: 10%;

  &:last-child {
    margin-right: 0;
    width: 40%;
  }
}

.radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.area {
  margin-top: 18px;
}
