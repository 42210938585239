@charset "UTF-8";
.form {
  background-color: #ffffff;
  padding: 24px;
  width: 350px;
  margin-bottom: 30px;
  font-family: 'HelveticaNeue';
  font-weight: 400; }
  @media (max-width: 460px) {
    .form {
      width: 100%; } }

label::after {
  content: '' !important; }

.input {
  margin: 0;
  text-align: left;
  width: 100%; }
  .input :global .ant-form-item-required::before {
    display: none; }
  .input :global .ant-form-item-label {
    margin-bottom: 10px; }
  .input :global .ant-form-item-required::after {
    content: '★';
    color: #00AC62;
    font-size: 7px;
    top: -6px;
    right: -2px; }

.divider {
  width: 100%;
  height: 1px;
  background-color: #666666;
  display: block;
  margin: 10px 0; }

.actions {
  display: flex;
  justify-content: center; }

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px; }
  .footer .divider {
    width: 42px; }
  .footer a {
    color: #00AC62; }

.forgot {
  display: block;
  text-align: right;
  position: relative;
  top: -20px; }
