@import '~style-assets/colors';
@import '~style-assets/breakpoints.scss';
@import '~style-assets/fontMixins.scss';

.modal {
  text-align: center;
  color: $e-black;
  top: 30%;

  @include helvetica-regular;

  :global {
    .ant-modal-content {
      border-radius: 0;
      box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
    }
  }

  .img {
    width: 30%;
  }

  p.title {
    padding: 10px 50px;
    font-size: 27px;

    @include for-tablet-portrait-down {
      font-size: 20px;
      padding: 0 16px;
    }
  }

  p {
    padding: 0 50px;
    font-size: 16px;

    @include for-tablet-portrait-down {
      padding: 0 16px;
    }
  }
  p:last-child {
    padding: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center {
  text-align: center;
}

.addAssetModal {
  p.title {
    padding: 10px;
    font-size: 27px;
    text-align: center;

    @include for-tablet-portrait-down {
      font-size: 20px;
      padding: 0 16px;
    }
  }

  p {
    padding: 0 10px;
    font-size: 16px;

    @include for-tablet-portrait-down {
      padding: 0 16px;
    }
  }
  p:last-child {
    padding: 0 10px;
  }
}

.sendTokenModal {
  p {
    padding: 4px 0;
  }
}

.downloadTransactionModal {
  :global {
    .ant-modal-close {
      color: black;
    }
  }

  text-align: center;

  div {
    text-align: center;
  }

  p {
    padding: 4px 0;
  }

  h2 {
    font-size: 27px;
  }

}
