@charset "UTF-8";
.container {
  text-align: center;
  max-width: 350px;
  margin: 80px auto;
  padding-bottom: 20px;
  background-color: #ffffff;
  color: #262626;
  font-family: 'HelveticaNeue';
  font-weight: 400; }

.title {
  font-size: 22px;
  font-family: 'HelveticaNeue';
  font-weight: 500;
  padding-top: 30px; }

.subtitle {
  margin-top: 10px;
  font-size: 14px; }

.form {
  padding: 24px;
  width: 350px;
  margin: 0 auto; }
  .form :global .ant-form-item-children-icon {
    display: none; }
  .form :global .ant-radio-wrapper {
    display: block;
    color: #262626; }

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }

.input {
  margin: 0;
  border: none;
  border-bottom: 1px solid #F2F1F1;
  width: 40px;
  text-align: center;
  font-size: 27px; }
  .input::placeholder {
    color: #E8E8E8; }
  .input:focus {
    outline: none;
    border-bottom-color: #00AC62; }
  .input :global .ant-form-item-required::before {
    display: none; }
  .input :global .ant-form-item-required::after {
    content: '★';
    color: #00AC62;
    font-size: 7px;
    top: -6px;
    right: -2px; }

.divider {
  width: 100%;
  height: 1px;
  background-color: #666666;
  display: block;
  margin: 10px 0; }

.actions {
  display: flex;
  justify-content: center; }

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px; }
  .footer .divider {
    width: 42px; }
  .footer a {
    color: #00AC62; }

.forgot {
  display: block;
  text-align: right;
  position: relative;
  top: -20px; }

.button,
.button:focus {
  font-family: 'HelveticaNeue';
  font-weight: 400;
  line-height: 18px;
  padding: 4px 0;
  margin: 0 auto;
  border-radius: 30px;
  height: 36px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #262626;
  color: #F2F1F1;
  width: 270px;
  border-color: #262626; }

.button {
  font-size: 14px;
  font-family: 'HelveticaNeue';
  font-weight: 500; }
  .button:hover {
    background-color: #00AC62;
    border-color: #00AC62;
    color: #F2F1F1;
    box-shadow: 0 3px 2px 0 #666666;
    font-size: 14px; }
  .button:focus {
    font-size: 14px; }
  .button:active {
    font-size: 14px;
    bottom: -2px; }

.divider {
  width: 36px;
  height: 1px;
  margin: 0 auto 12px;
  background-color: #C2C1C1;
  display: block; }

.question {
  margin-bottom: 0; }

.link {
  color: #00AC62; }
  .link:hover {
    color: #262626;
    cursor: pointer; }
