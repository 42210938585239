@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 35px;
  color: $e-black;
  border-bottom: 9px solid $e-white;

  @include for-768-down {
    align-items: center;
  }

  @include for-tablet-portrait-down {
    padding: 28px 20px;
  }

  h3 {
    margin: 0 0 30px;
    font-size: 18px;
    @include helvetica-medium;
  }
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.button,
.button:hover,
.button:focus {
  padding: 0;
  margin: 0;
  font-size: 14px;
  height: 30px;
  width: 180px;
  text-transform: capitalize;
  border-radius: 30px;
  @include helvetica-regular;
}

.submit,
.submit:hover,
.submit:focus {
  @extend .button;
  display: block;
  padding: 0 58px;
  font-size: 14px;
  @include helvetica-regular;
}

.submit {
  background-color: $e-black;
  color: $e-white;

  &:hover,
  &:focus {
    background-color: $orange;
    color: $e-white;
    border-color: transparent;
    box-shadow: 0 3px 2px 0 $dove-gray;
  }

  &:active {
    background-color: $e-black;
    color: $e-white;
    bottom: -2px;
  }
}
