@import '~style-assets/colors';

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.container {
  @extend .column;
  background-color: $white;
  margin-right: 30px;
  margin-bottom: 30px;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.logo {
  @extend .row;
  justify-content: center;
  align-content: center;
  width: 270px;
  height: 170px;
  background: url('../../../assets/images/no_img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 160px;
}

.image {
  max-width: 100%;
}

.info {
  @extend .column;
  align-items: flex-start;
  padding: 10px;
  position: relative;

  &:after {
    display: block;
    bottom: 0;
    left: 5%;
    width: 90%;
    height: 1px;
    position: absolute;
    content: '';
    background-color: $dove-gray;
  }
}

.name {
  font-size: 14px;
  letter-spacing: 0.5px;
}

.location {
  font-size: 10px;
  margin-left: 5px;
}

.navigation {
  @extend .row;
  justify-content: flex-end;
  padding: 8px;
  position: relative;
}

.link {
  position: absolute;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    color: $orange;
  }
}
