@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.header {
  font-size: 10px;
  position: relative;
  margin: 10px;

  &:after {
    width: 120%;
    height: 1px;
    content: '';
    position: absolute;
    background-color: $dove-gray;
    bottom: -1px;
    left: -10%;
  }
}

.title {
  letter-spacing: 1px;
  font-size: 25px;
}

.form {
  background-color: $white;
  padding: 20px;
  width: 500px;

  :global {
    .ant-select-selection__placeholder {
      display: block !important; // no other way to override ant's display none for select placeholder
    }
  }
}

.input {
  margin: 0;
  text-align: left;
}

.sInput {
  @extend .input;
  width: 48%;
  margin-right: 4%;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.mInput {
  @extend .input;
  width: 100%;
}

.footer {
  margin-top: 3px;
}

.phoneInput {
  @extend .sInput;

  input {
    width: 100% !important;
    height: 31px !important;
  }
}

.row {
  display: flex;
  width: 100%;
}

.leftAlignedRow {
  @extend .row;
  justify-content: flex-start;
}

.bottomAlignedRow {
  @extend .row;
  align-items: flex-end;
}

.button,
.button:hover,
.button:focus {
  background-color: transparent;
  color: $e-black;
  border-radius: 30px;
  height: 36px;
  text-transform: uppercase;
  margin: 10px;
  letter-spacing: 1px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.back {
  @extend .button;
  border: none;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 80%;
    left: 10%;
    bottom: 7px;
    height: 1px;
    background: $e-black;
  }
}

.submit {
  @extend .button;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $dove-gray;
  display: block;
  margin: 10px 0;
}

.subTitle {
  display: flex;
  justify-content: flex-start;

  & > span {
    @include helvetica-bold;
  }
}

.file {
  margin-right: 8px;
}
