@import "~style-assets/colors";
@import "~style-assets/fontMixins";

.footer {
  text-align: right;
  background: $white;

  ul {
    @include helvetica-regular;
    margin: 0;
    padding: 0;

    & > li {
      display: inline-block;
      margin: 0 20px;

      a {
        color: $e-black;

        &:hover:not(:active) {
          color: $orange;
          text-decoration: none;
        }

        &:focus, &:active {
          border-bottom: 1px solid $orange;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
