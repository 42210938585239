@font-face {
  font-family: 'Baton Bold';
  src: url('../fonts/Baton-Turbo-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baton Book';
  src: url('../fonts/Baton-Turbo-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baton Medium';
  src: url('../fonts/Baton-Turbo-Medium.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baton Regular';
  src: url('../fonts/Baton-Turbo-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts//HelveticaNeueBd.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueLight.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
