@import '~style-assets/breakpoints.scss';
@import '~style-assets/colors.scss';

.img {
  width: 100%;
  height: 507px;
  object-fit: cover;

  @include for-tablet-landscape-down {
    height: unset;
    min-height: 320px;
  }

  @include for-768-down {
    height: unset;
  }

  @include for-tablet-portrait-down {
    min-height: 260px;
  }

  @include for-phone-only {
    object-fit: unset;
  }
}

.banner {
  width: 100%;
  height: 507px;
  background-color: $e-white;
}
