@import '../../assets/styles/colors';
@import '../../assets/styles/fontMixins';
@import '../../assets/styles/breakpoints';

.wrapper {
  background: $e-white;
  height: 100%;
  padding: 35px 25px;
  @include helvetica-regular;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  padding: 0 20px;
}

.dashboard {
  padding: 14px 21px;
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
}

.ticker,
.header {
  @include helvetica-bold;
  color: $black;
  font-size: 16px;

  @include for-tablet-landscape-down {
    font-size: 12px;
  }
}

.companyName {
  font-size: 16px;

  @include for-tablet-landscape-down {
    font-size: 12px;
  }
}

.main {
  font-size: 27px;
  @include helvetica-medium;

  @include for-tablet-landscape-down {
    font-size: 18px;
  }
}
.sub {
  @include helvetica-regular;
  font-size: 12px;
  margin-top: -7px;
}

.oneDayChange {
  position: relative;

  &.positive:after {
    border-color: transparent transparent $green transparent;
    border-width: 0 5px 8.7px 5px;
  }
  &.negative:after {
    border-color: $red transparent transparent transparent;
    border-width: 8.7px 5px 0 5px;
  }
  &.neutral:after {
    border-color: transparent transparent transparent transparent;
    border-width: 0 5px 8.7px 5px;
  }

  &:after {
    content: '';
    position: absolute;
    right: -13px;
    top: 9px;
    width: 0;
    height: 0;
    border-style: solid;
  }
}

.currency {
  @include helvetica-regular;
  font-size: 16px;
}

.marketBlock {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  color: $black;
}
.marketData {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  margin-right: 21px;

  @include for-768-down {
    height: auto;
  }

  & > div {
    display: inline-flex;
    flex-direction: column;
  }
}

.divider {
  border-bottom: 1px solid $e-gray;
}

.portfolio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 275px;
  height: 190px;
  padding: 10px 15px;
  border: 1px solid $orange;
}

.portfolioBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 16px;
  color: $black;
}

.portfolioTicker {
  @include helvetica-regular;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.header {
  padding-bottom: 5px;
}
.amounts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}
.tokenAmount {
  font-size: 24px;
  line-height: 29px;
  color: $black;
}
.fiatAmount {
  color: #b4b4b4;
  font-size: 12px;
}
.logo {
  width: 70px;
  margin-bottom: 12px;
}
.link {
  width: 100%;
  font-size: 14px;
  color: $e-gray;
  text-align: right;
}

.block {
  @include helvetica-regular;
  display: flex;
  flex-direction: column;
  flex: calc(33% - 21px);
  font-size: 14px;
  color: $black;
  margin-right: 21px;
  margin-top: 8px;
  padding-bottom: 12px;

  .title {
    color: $e-gray;
    margin-bottom: 5px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
}

.chart {
  width: 100%;
}
