@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.form {
  width: 100%;
  max-width: 400px;
  padding: 20px 20px 0;
  background-color: $white;
  margin-bottom: 30px;

  @include super-small {
    max-width: 100%;
  }
}

.input {
  margin: 0;
  text-align: left;
  width: 100%;

  label {
    font-size: 16px;
  }

  input {
    font-size: 14px;
  }
}

.title {
  max-width: 720px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: $e-black;
  text-align: center;
  text-align-last: auto;

  @include helvetica-medium;
  margin: 0 auto 25px;

  @include for-phone-only {
    text-align-last: auto;
  }
}

.actions {
  display: flex;
  width: 370px;
  justify-content: flex-end;
  margin: 0 auto;
  padding-top: 20px;

  @include for-phone-only {
    max-width: 100%;
  }
}

.btn {
  font-size: 14px;

  &:hover,
  &:active,
  &:focus {
    font-size: 14px;
  }
}

.text {
  padding-bottom: 20px;
  text-align: left;
}
