@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';
@import '~style-assets/media-query-points.scss';

.content {
  display: flex;
  align-items: flex-start;
  width: 95%;
  max-width: 900px;
  min-height: 620px;
  transform: translateY(-64px);
  @include helvetica-regular;

  @include for-tablet-portrait-down {
    flex-direction: column;
    min-height: 0;
  }
}

.block {
  display: flex;
  flex-wrap: wrap;
  color: $e-black;
  width: 100%;

  @include for-768-down {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.head {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);
  font-size: 16px;
  background-color: #f2f1f1;
  padding: 16px 32px;
  color: $e-black;
  margin-bottom: 20px;

  @include for-768-down {
    width: 100%;
  }

  @include for-tablet-portrait-down {
    order: 1;
  }

  a {
    color: $e-black;
    margin-bottom: 10px;

    &:hover {
      color: $orange;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      @include for-phone-only {
        width: auto;
        margin: 0;
      }
    }

    .backIcon {
      color: $orange;
      padding-right: 4px;
    }
  }

  .status {
    font-size: 10px;
    border-radius: 20px;
    border: 1px solid $gray;
    padding: 2px 5px;
    background-color: $white;
    box-shadow: 0 3px 3px $gray;
  }

  .days {
    font-size: 24px;
    @include helvetica-medium;
  }
}

.aside {
  position: absolute;
  right: 10px;

  @include for-768-down {
    position: relative;
    height: 350px;
    right: 0;
  }

  @include for-phone-only {
    height: 320px;
  }

  @include super-small {
    height: max-content;
  }
}

.subFooter {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  a {
    color: #4b98c1;
    font-size: 11px;
    align-self: flex-end;
    margin-top: 6px;
    margin-bottom: -6px;
    text-decoration: underline;
  }
}

.row {
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-bottom: 4px;

  span {
    color: $green;
    margin-right: 4px;
  }
}

.progress {
  width: 100%;
  height: 12px;
  background-color: $gray;
  position: relative;
}

.subline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray;
  align-self: center;
}

.body {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);

  @include for-768-down {
    order: 3;
    width: 100%;
  }

  @include for-tablet-portrait-down {
    margin-top: 30px;
  }
}

.subHeader {
  width: 95%;
  max-width: 680px;
  margin: 0 auto 20px;

  :global {
    .ant-tabs-bar {
      padding-bottom: 30px;
      margin-bottom: 16px;
      border-bottom: 1px solid $e-white;
    }

    .ant-tabs-nav {
      width: 100%;

      & > div {
        display: flex;
        justify-content: space-between;
      }
    }

    .ant-tabs-nav-scroll {
      display: flex;
      justify-content: center;
    }

    .ant-tabs-tab {
      color: #d2d1d1;
      font-size: 16px;
      flex-grow: 1;
      margin-right: 0;

      &:hover {
        color: $e-black;
      }
    }

    .ant-tabs-tab-active {
      color: $e-black;
    }

    .ant-tabs-ink-bar {
      background-color: $orange;
    }
  }

  img {
    width: 100%;
  }
}

.links {
  display: flex;
  justify-content: center;

  span {
    margin: 20px 70px;
  }
}

.footer {
  background-color: $e-black;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  display: none;
  width: calc(100% - 260px);

  @include for-768-down {
    width: 100%;
    order: 3;
    display: block;
    margin: auto;
    text-align: center;
  }

  p {
    color: $e-white;
    font-size: 14px;
    letter-spacing: 0.3px;
    padding: 25px 13px 11px 13px;

    @include for-768-down {
      font-size: 16px;
    }

    @include for-tablet-portrait-down {
      text-align: center;
    }
  }

  input {
    @include for-768-down {
      width: 250px;
      height: auto;
    }
  }
}
