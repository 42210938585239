@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  align-items: center;
  padding: 28px 35px;
  color: $e-black;
  border-bottom: 9px solid $e-white;

  @include for-768-down {
    align-items: center;
    text-align: center;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    border-right: none;
  }

  :global {
    .ant-form-item-label {
      margin-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
      margin-right: 8px;
      min-width: 240px;

      @media only screen and (max-width: 550px) {
        min-width: unset;

      }
    }
  }

  h3 {
    margin: 0 10px 0 0;
    font-size: 18px;
    @include helvetica-medium;

    @media only screen and (max-width: 550px) {
      margin-left: auto;
      font-size: 16px;
      @include helvetica-regular;
    }
  }

  p {
    margin-bottom: 10px;
  }

  .form {
    padding: 0;
    margin-bottom: 0;

    min-width: 200px;
    width: auto;

    display: flex;
    align-items: center;
    flex: 1;

    @media only screen and (max-width: 550px) {
      margin-left: auto;
      min-width: 320px;
    }

    @include for-phone-only {
      min-width: 260px;
    }

    label {
      font-size: 16px;
    }

    button,
    button:focus,
    button:active,
    button:hover {
      padding: 0;
      margin-left: auto;
      font-size: 14px;
      height: 30px;
      width: 120px;
      @include helvetica-regular;
    }
  }
}

.referral {
  font-size: 18px;
  color: $orange;
}
