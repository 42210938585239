@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid $gray;
}

.left {
  display: flex;
  padding: 3px 0;
  padding-left: 10px;

  .icon {
    width: 20px;
    height: 20px;
    border: 1px solid $e-black;
    margin-right: 20px;
  }
}

.right {
  border-left: 1px solid $gray;
  min-width: 101px;
  padding: 3px 0;
  font-size: 12px;

  p {
    text-align: center;
    padding: 0 15px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  a {
    color: $orange;
  }

  .dowload {
    color: $e-black;
    border-top: 1px solid $gray;
  }
}
