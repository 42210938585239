@import './colors';
@import './fontMixins';

:global {
  .ant-btn.cta {
    width: 190px;
    height: 42px;
    background: $e-black;
    color: $e-white;
    text-transform: uppercase;
    border-radius: 21px;
    border: 0;

    &:hover,
    &:focus {
      box-shadow: 0 5px 1px 0 $gray;
    }

    &:active {
      background: $green;
    }

    &:disabled {
      background: $gray;
    }
  }

  @mixin tag {
    border: 0;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    color: $e-white;
  }

  .ant-tag.new {
    @include tag;
    background: $e-black;
  }

  .ant-tag.active {
    @include tag;
    background: $green;
  }

  .blue-button {
    height: 67px;
    width: 100%;
    margin-top: 38px;

    background: $blue;
    color: $white;
    border-radius: 4px;
    border: none;
    font-size: 27px;
    &:hover,
    &:focus {
      background: $blue;
      border-color: $blue;
      color: $white;
    }
  }

  .auth-controls {
    @include helvetica-regular;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > button {
      @extend .blue-button;

      @media only screen and (max-width: 1280px) {
        font-size: 12px;
        height: 30px;
        margin-top: 15px;
      }
    }
  }
}

.form-input {
  align-self: stretch;
  margin-bottom: 0;
  line-height: 1;

  input {
    @include helvetica-regular;
    border-color: $mercury;
    height: 58px;
    padding-left: 22px;

    @media only screen and (max-width: 1280px) {
      height: 30px;
      padding-left: 10px;
    }
  }

  :global {
    .ant-form-item-control-wrapper:first-of-type
      > .ant-form-item-control
      > .ant-form-item-children
      > .ant-row.ant-form-item {
      margin-bottom: 0;
      margin-top: 10px;

      @media only screen and (max-width: 1280px) {
        margin-top: 0;
      }
    }

    .ant-form-item-label {
      @include helvetica-medium;
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: 1px;
      text-align: left;
      color: $black;

      label {
        white-space: normal;
        text-transform: uppercase;
        font-size: 16px;
        &:after {
          display: none;
        }

        @media only screen and (max-width: 1280px) {
          font-size: 12px;
          line-height: 13px;
          letter-spacing: 0;
        }
      }
    }

    .ant-form-item-control {
      line-height: 1;

      .ant-input {
        padding-right: 0;
        margin-top: 10px;

        @media only screen and (max-width: 1280px) {
          font-size: 11px;
        }
      }
      &.has-success .ant-form-item-children:after {
        display: none;
      }
    }
  }
}
