@import '~style-assets/fontMixins';
@import '~style-assets/colors';
@import '~style-assets/forms';
@import '~style-assets/breakpoints.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $e-white;
  min-height: 100vh;
  padding: 70px 0;

  @include for-tablet-portrait-down {
    min-height: 70vh;
  }
}

.sumsubContainer {
  width: 540px;

  @include for-tablet-portrait-down {
    width: 90%;
  }

  @include for-phone-only {
    width: 100%;
  }

  iframe  {
    min-height: 380px;
  }
}
