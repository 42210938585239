@import '~style-assets/colors.scss';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

:global {
  .ant-collapse-header {
    color: $e-black !important;
    font-size: 24px;
    @include helvetica-medium;
    border-bottom: 1px solid $orange;

    @include for-tablet-landscape-down {
      font-size: 20px;
    }

    @include for-768-down {
      font-size: 16px;
    }

    @include for-phone-only {
      font-size: 14px;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
  }
}

.text {
  margin-top: 24px;
  color: $e-black;
  white-space: pre-wrap;
}

.row {
  display: flex;

  .par {
    display: flex;
    flex-direction: column;
  }
}

.par {
  margin: 10px 0;

  img {
    max-width: 120px;
    margin-right: 40px;
  }
}
