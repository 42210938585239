@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/breakpoints.scss';

.modal {
  width: 100% !important;
  max-width: 700px;

  :global {
    .ant-modal-body {
      padding-right: 0 !important;
    }
  }

  table {
    width: 100%;

    td {
      border: 1px solid $e-black;

      p {
        padding: 0 10px;

        @include for-phone-only {
          width: 100%;
          padding: 0 4px;
          font-size: 12px;
        }
      }
    }
  }
}

.agreement {
  background-color: $white;
  max-width: 460px;
  font-size: 16px;
  padding: 24px;

  @include for-phone-only {
    padding: 16px;
  }

  p {
    text-align: left;

    @include for-phone-only {
      margin: 0;
      width: unset;
    }
  }

  :global {
    .ant-form-item {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .ant-form-item-label {
      white-space: unset;
      text-align: left;
      margin-left: 10px;
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .ant-form-item-control-wrapper {
      @media (max-width: 575px) {
        width: unset;
      }
    }
  }

  a {
    color: $orange;

    &:hover {
      text-decoration: underline;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.value {
  color: $orange;
  font-size: 15px;
  min-width: 100px;
  text-align: right !important;

  @include for-phone-only {
    font-size: 14px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray;
  align-self: center;
  margin: 5px 0;

  @include for-tablet-portrait-down {
    margin-top: 0;
  }
}

.checkboxBlock {
  @include for-phone-only {
    padding-left: 16px;
  }
}

.mt20 {
  margin-top: 20px;
}

.pl16 {
  @include for-phone-only {
    padding-left: 16px;
  }
}

.actions {
  display: flex;
  width: 370px;
  justify-content: flex-end;
  margin: 0 0 0 auto;
  padding-top: 20px;

  @include for-phone-only {
    max-width: 100%;
  }
}

.btn {
  font-size: 14px;

  &:hover,
  &:active,
  &:focus {
    font-size: 14px;
  }
}

.mustAgree {
  max-height: 500px;
  padding-right: 24px;

  overflow: scroll;
  overflow-x: hidden;
}

.subscriptionTextPar {
  margin-bottom: 16px;
}

.text {
  margin-bottom: 16px;
}
