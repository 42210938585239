@import '~style-assets/colors';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  color: $e-black;
  padding-bottom: 78px;
}
