@import '~style-assets/colors';
@import '~style-assets/fontMixins.scss';
@import '~style-assets/media-query-points.scss';

.list {
  @include helvetica-regular;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 110px;
  align-items: stretch;
  width: 100%;
  &.transparent {
    max-width: 820px;
    .item {
      background: #fff;
      flex: 1 auto 30%;
      .title {
        @include helvetica-medium;
      }
    }
  }
}

.item {
  width: 352px;
  background-color: $e-white;
  padding: 25px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.image {
  height: 80px;
  display: block;
}

.title {
  font-size: 16px;
  margin-top: 28px;
  margin-bottom: 0;
}

.divider {
  width: 36px;
  height: 1px;
  background-color: $orange;
  display: block;
  margin: 12px 0;
}

.content {
  font-size: 16px;
  padding-left: 15px;
}

@media screen and (max-width: $min-sm) {
  .list {
    align-items: center;
  }
}

@media screen and (min-width: $min-sm) {
  .list {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    &.transparent {
      .item {
        &:not(:last-of-type) {
          margin-right: 3.333%;
        }
      }
    }
  }

  .item {
    padding: 13px 24px 30px;
    margin: 0 20px 0 0;
    min-height: 292px;
    width: 29%;

    &:last-child {
      margin-right: 0;
    }
  }

  .title {
    display: block;
    height: 90px;
  }
}

@media screen and (min-width: $min-l) {
  .title {
    display: block;
    height: 70px;
  }

  .list {
    margin: 0 0 110px;
  }

  .item {
    padding: 15px;
    margin: 0 20px 0 0;
    min-height: 292px;
    width: 352px;

    &:last-child {
      margin-right: 0;
    }
  }
}
